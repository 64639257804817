import { useLazyQuery, useQuery } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import SortableResultsTable from 'components/UI/SortableResultsTable'
import { useContext, useState } from 'react'
import { Col, Form, Row, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AuthContext from 'store/auth-context'
import { getFormattedDate, getFormattedDateWithHM } from 'utils/functions'
import { RefreshIcon } from '@heroicons/react/solid'
import GET_ORDER_BY_EVENT_TYPE_NEW_LISTING from 'graphql/queries/getDOOrderByEventTypeNewListing'

interface Props {
  orderEventTypeId: number
}
const GestionCommandeResultsNew: React.FC<Props> = ({ orderEventTypeId }) => {
  const { t } = useTranslation()

  const ordersColumns = [
    {
      headerName: t('orders_monitoring.headers.country'),
      accessor: 'country',
      disableSortBy: true,
    },
    {
      headerName: t('orders_monitoring.headers.order_number'),
      accessor: 'oo_key', // pour le tri
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.creation_date'),
      accessor: 'o_creationDate', // pour le tri
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.customer_name'),
      accessor: 'customer_full_name',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.days_since_last_event'),
      accessor: 'last_event',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.user_login_create'),
      accessor: 'user_login_create',
      disableSortBy: false,
    },
    {
      headerName: t('orders_monitoring.headers.comment'),
      accessor: 'comment',
      disableSortBy: true,
    },
    {
      headerName: t('orders_monitoring.headers.total_amount'),
      accessor: 'total_amount',
      disableSortBy: false,
    },
  ]

  /**
   * @returns Array with data to display in table (done here, else if won't refresh at the right time if made in TableOrdersByEventType file)
   */
  const formatOrdersData = () => {
    let ordersDataFormatted: any[] = [] 
    const objData = ordersByEvent.getDOOrderByEventTypeNewListing.edges
    for (let orderByEvent of objData) {
      const daysSinceEventCreated =  Math.round((new Date().getTime() / 1000 - orderByEvent.node.eventCreationDate) /(24 * 60 * 60))
      // total reminder sent so substract the first mail sent
      const totalReminderSent = orderByEvent.node.totalMailSent -1
      let orderObj = {
          daysSinceEventCreated: daysSinceEventCreated,
          country: orderByEvent.node.site,
          oo_key: orderByEvent.node.orderKey,
          oo_id: orderByEvent.node.orderId.toString(),
          o_creationDate: getFormattedDate(orderByEvent.node.orderCreationDate * 1000),
          customer_full_name:
          orderByEvent.node.customerFullname,
          last_event: getFormattedDateWithHM(orderByEvent.node.eventCreationDate * 1000) + " (" + daysSinceEventCreated + ")" + (totalReminderSent > 0?' ['+totalReminderSent+']':''),
          user_login_create: orderByEvent.node.userLoginCreate,
          comment: orderByEvent.node.eventComment,
          total_amount: !isNaN(orderByEvent.node.totalAmount) ? orderByEvent.node.totalAmount : '',
      }
      ordersDataFormatted.push(orderObj)
    }
    return ordersDataFormatted
  }

  const onRowClick = (rowId: string) => {
    window.open(`/bo/relation_client/gestion_commande_client/${rowId}`)
  }

  const initialFilters = {
    siteFilter: 'FR,ES,IT',
    fromDate: null,
    toDate: null,
  }

  const [filters, setFilters] = useState<any>(initialFilters)
  const [ordersByEvent, setOrdersByEvent] = useState<any>()

  const authCtx = useContext(AuthContext)

  const {error: ordersByEventError, loading: ordersByEventLoading } = useQuery(
    GET_ORDER_BY_EVENT_TYPE_NEW_LISTING,
    {
      fetchPolicy: 'no-cache',
      variables: {
        orderEventTypeId,
        limit: authCtx.websiteSettings.infiniteScrollTableInitialRowsCount,
        siteFilter: filters.siteFilter,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
      },
      onCompleted(data) {
          setOrdersByEvent(data)
      },
    }
  )

  const [fetchMoreOrders] = useLazyQuery(
    // FIXME : piste d'amélioration à prévoir : utiliser fetchMore de la query ci-dessus et passer en paramètres du SortableResultsTable ce
    // fetchMore({variables:{offset: ordersData.getDOOrderByDOOrderEventTypeListing.edges.length, limit: authCtx.websiteSettings.infiniteScrollTableFetchMoreRowsCount}})
    // --> les autres paramètres seront automatiquement remplis. Mais quid de la MAJ des données ?
    // Source: https://www.apollographql.com/docs/react/pagination/core-api/
    GET_ORDER_BY_EVENT_TYPE_NEW_LISTING,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        // Concatenating previous edges with new fetched edges
        let newEdges = [...ordersByEvent.getDOOrderByEventTypeNewListing.edges,
          ...data.getDOOrderByEventTypeNewListing.edges
        ]
        // Creating new object for data (with edges, orderEventType, totalOrders...)
        let newOrdersData = {
          getDOOrderByEventTypeNewListing: {
            edges: newEdges,
            orderEventType:
              data.getDOOrderByEventTypeNewListing.orderEventType,
            totalOrders: data.getDOOrderByEventTypeNewListing.totalOrders,
          },
        }
        setOrdersByEvent(newOrdersData)
      },
    }
  )

  const fetchMoreOrdersWithVariables = () => {
    fetchMoreOrders({
      variables: {
        orderEventTypeId:
          +ordersByEvent.getDOOrderByEventTypeNewListing.orderEventType.id,
        limit: authCtx.websiteSettings.infiniteScrollTableFetchMoreRowsCount,
        offset: ordersByEvent
          ? ordersByEvent.getDOOrderByEventTypeNewListing.edges.length
          : 0,
        siteFilter: filters.siteFilter,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
      },
    })
  }

  const countries: any = [
    {
      key: 'inline-checkbox-fr',
      value: 'FR',
    },
    {
      key: 'inline-checkbox-es',
      value: 'ES',
    },
    { key: 'inline-checkbox-it', value: 'IT' },
  ]

  const onCheckboxChange = (e: any) => {
    const domainSites = filters.siteFilter.split(',')
    const selectedCountry = countries.find(
      (country: any) => country.key === e.target.id
    )
    const countryIndex = domainSites.indexOf(selectedCountry.value)
    if (countryIndex > -1) domainSites.splice(countryIndex, 1)
    else domainSites.push(selectedCountry.value)
    setFilters({
      siteFilter: domainSites.join(','),
      fromDate: filters.fromDate,
      toDate: filters.toDate,
    })
  }

  const onDateChange = (e: any) => {
    if (e.target.id === 'fromDate')
      setFilters({
        siteFilter: filters.siteFilter,
        fromDate: e.target.value.length ? e.target.value : null,
        toDate: filters.toDate,
      })
    else if (e.target.id === 'toDate')
      setFilters({
        siteFilter: filters.siteFilter,
        fromDate: filters.fromDate,
        toDate: e.target.value.length ? e.target.value : null,
      })
  }

  const resetDateButtonHandler = () => {
    setFilters({
      siteFilter: filters.siteFilter,
      fromDate: null,
      toDate: null
    })
  }
  if (ordersByEventLoading) return <SkeletonBase count={5} />

  if (ordersByEventError) return <p>`Error! ${ordersByEventError.message}`</p>

  return (
    <div data-cy="gestionCommandeResults">
      <Row className="g-2 p-3">
        <Col>
          <div key="inline-checkbox" className="mb-3">
            {countries.map((country: any) => {
              return (
                <Form.Check
                  key={country.key}
                  inline
                  label={country.value}
                  name="country-group"
                  type="checkbox"
                  id={country.key}
                  defaultChecked={filters.siteFilter.includes(country.value)}
                  onChange={onCheckboxChange}
                />
              )
            })}
          </div>
        </Col>
        <Col>
          <Form.Group as={Row} className="mb-3 justify-content-end" controlId="fromDate">
            <Form.Label column sm={2} className="text-nowrap">
              Du :
            </Form.Label>
            <Col md="auto">
              <Form.Control
                type="date"
                placeholder="Du"
                defaultValue={filters.fromDate}
                onChange={onDateChange}
                className="w-100"
              />
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} className="mb-3 justify-content-end me-2" controlId="toDate">
            <Form.Label column sm={2} className="text-nowrap">
              Au :
            </Form.Label>
            <Col md="auto">
              <Form.Control
                type="date"
                placeholder="Au"
                defaultValue={filters.toDate}
                onChange={onDateChange}
                className="w-100"
              />
            </Col>
          </Form.Group>
        </Col>
        <Col md="auto">
          <Row>
            <Button onClick={resetDateButtonHandler} className="p-0 ps-1 mt-1" variant="outline-primary">
              <RefreshIcon className="header-title-icon" />
            </Button>
          </Row>
        </Col>
      </Row>
      {ordersByEvent && (
        <>
          <h4>
            {
              ordersByEvent.getDOOrderByEventTypeNewListing.orderEventType
                .label
            }{' '}
            ({ordersByEvent.getDOOrderByEventTypeNewListing.totalOrders})
          </h4>
          {ordersByEvent.getDOOrderByEventTypeNewListing.totalOrders === 0 && (
            <div className="no-result">Aucun résultat</div>
          )}
          {ordersByEvent.getDOOrderByEventTypeNewListing.totalOrders > 0 && (
            <div className="results">
              <SortableResultsTable
                tableColumns={ordersColumns}
                tableData={formatOrdersData()}
                onClickRows={onRowClick}
                dataTotalSize={
                  ordersByEvent.getDOOrderByEventTypeNewListing.totalOrders
                }
                fetchMoreData={fetchMoreOrdersWithVariables}
                displayLineNumbers={false}
                tableType="monitoring"
                eventTypeLabel={ordersByEvent.getDOOrderByEventTypeNewListing.orderEventType.label}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default GestionCommandeResultsNew
