import { useMutation } from '@apollo/client'
import BULK_DELETE_DO_ORDERLINES from 'graphql/mutations/bulkDeleteDOOrderLines'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'
import { ChangeEvent, useContext } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'

interface Props {
  order: orderType
  checkedIdList: string[]
  resetStateType: (newStateType: string) => void
  comment: string
  setComment: (value: React.SetStateAction<string>) => void
  resetCheckedIdList: (orderLinesIds: string[]) => void
}
const DeleteLinesForm: React.FC<Props> = ({
  order,
  checkedIdList,
  resetStateType,
  comment,
  setComment,
  resetCheckedIdList,
}) => {
  const { toast } = useContext(ToastContext)

  const [bulkDeleteDOOrderLines, { loading: bulkDeleteLoading }] = useMutation(
    BULK_DELETE_DO_ORDERLINES,
    {
      variables: {
        orderLineIdList: JSON.stringify(checkedIdList),
        comment,
      },
      onCompleted: (data) => {
        if (
          data.bulkDeleteDOOrderLines.output.deleteFailOrderLinesIds.length > 0
        ) {
          toast({
            title: 'Lignes non supprimées :',
            message: `${data.bulkDeleteDOOrderLines.output.deleteFailOrderLinesIds?.join(
              ', '
            )} ${
              data.bulkDeleteDOOrderLines.output.deleteFailOrderLinesIds
                .length === order.orderLines.length
                ? ' car il doit rester au moins une ligne produit dans le détail de la commande'
                : ''
            }`
          })
        }
        if (
          data.bulkDeleteDOOrderLines.output?.deleteSuccessOrderLinesIds
            .length > 0
        ) {
          toast({
            title: 'Modifications enregistrées :',
            message:
              `Lignes supprimées : ` +
              data.bulkDeleteDOOrderLines.output.deleteSuccessOrderLinesIds?.join(
                ', '
              )
          })
        }
        resetStateType('')
        resetCheckedIdList([])
      },
    }
  )
  const deleteHandler = async () => {
    if (order.orderLines.length === checkedIdList.length)
      toast({
        title: 'Lignes non modifiées :',
        message:
          checkedIdList.join(', ') +
          ' car il doit rester au moins une ligne produit dans le détail de la commande'
      })
    else if (checkedIdList.length) {
      if (
        window.confirm(
          'Voulez-vous vraiment supprimer ces lignes articles : ' +
            checkedIdList.join(', ') +
            ' ?'
        )
      )
        try {
          await bulkDeleteDOOrderLines({
            refetchQueries: [CQ_GET_ORDER],
            awaitRefetchQueries: true,
          })
        } catch (e) {
          console.log(e)
        }
    } else {
      alert('Veuillez sélectionner au moins une ligne à supprimer')
    }
  }

  return (
    <Container className="mb-3">
      <Row>
        <div className="ms-4 w-75">
          <Form.Group controlId="activeTextArea">
            <Form.Control
              name="activeTextArea"
              placeholder="Commentaire de suppression des lignes"
              as="textarea"
              className="border border-light border-2 rounded"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setComment(e.target.value)
              }}
              value={comment}
            />
          </Form.Group>
        </div>
        <Col className="d-flex align-items-end">
          <Button className="me-2" size="sm" onClick={() => resetStateType('')}>
            Retour
          </Button>
          <Button
            size="sm"
            disabled={bulkDeleteLoading || comment === ''}
            onClick={deleteHandler}
          >
            {bulkDeleteLoading ? (
              <Spinner
                variant="light"
                className="mx-4"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>Supprimer</>
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default DeleteLinesForm
