import { useMutation } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import LensReplacementForm from './LensReplacementForm'
import MenuContext from 'store/menu-context'
import AuthContext from 'store/auth-context'
import { REPLACE_PRODUCT } from 'graphql/mutations/replaceProduct'

interface Props {
  orderLineItem: any
  closeModal?: () => void
}

const EditLensReplacement: React.FC<Props> = ({
  orderLineItem,
  closeModal
}) => {
  const { toast } = useContext(ToastContext)
  const [selectedLensReplacement, setSelectedLensReplacement] = useState<any>()
  const menuContext = useContext(MenuContext)
  const authCtx = useContext(AuthContext)

  //Mutation : CM update orderLineItem and create events
  const [replaceProduct, { loading: mutationLoading }] =
    useMutation(REPLACE_PRODUCT, {
      onCompleted: (data) => {
        if (data.replaceProduct.output.cmFailComment) {
          toast({
            title: 'Attention ! ',
            message: data.replaceProduct.output.cmFailComment
          })
        } else {
          toast({
            title: 'Modification enregistrée : ',
            message: `Équipement ${data.replaceProduct.output.orderLineItem.orderLine?.[0]?.id}: ${selectedLensReplacement.node.name}`,
          })
        }

        if(data.replaceProduct.output.toPutPhysicallyBackToStock && (authCtx.user.roles[0] === 'logistique_kehl' || authCtx.user.fullName === 'admin'))
          menuContext.setSideBarNewEventCount(++menuContext.sideBarNewEventCount)

        if (closeModal)
          // Si on connait la fonction qui permet de fermer la modale, on la ferme
          closeModal()
      },
    })

  //execute the mutation
  const saveHandler = async () => {
    try {
      await replaceProduct({
        variables: {
          orderLineItemId: +orderLineItem.id,
          newProductId: +selectedLensReplacement.node.id,
        },
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <LensReplacementForm
        label={true}
        selectedLensReplacement={selectedLensReplacement}
        setSelectedLensReplacement={setSelectedLensReplacement}
      />
      <div className="d-flex justify-content-center mt-3">
        <Button onClick={saveHandler} disabled={mutationLoading}>
          {mutationLoading ? (
            <Spinner
              variant="light"
              className="mx-4"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>Enregistrer</>
          )}
        </Button>
      </div>
    </>
  )
}

export default EditLensReplacement
