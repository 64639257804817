import React, { useContext, useState } from 'react'
import Card from 'components/UI/Card'
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client'
import { GET_DO_ORDER_BY_ORDER_TYPE } from 'graphql/queries/getOrderByOrderType'
import CSVDownload from 'components/CSVDownload'
import AuthContext from 'store/auth-context'
import { constants } from 'utils/constants'

const ColishipCSV: React.FC = () => {
  const [orderType, setOrderType] = useState<string>('panier')
  const [orderIds, setOrderIds] = useState<number[]>([])
  const [csvData, setCsvData] = useState<string[][]>([])
  const [invalidOrderIds, setInvalidOrderIds] = useState<number[]>([])
  const [invalidDeliveryOrders, setInvalidDeliveryOrders] = useState<string[]>([])
  const deliveryModeKeys_2025 = [
    constants.deliveryModeKeys.CHRONO2SHOP, 
    constants.deliveryModeKeys.CHRONO_CLASSIQUE, 
    constants.deliveryModeKeys.CHRONO_EXPRESS, 
    constants.deliveryModeKeys.COLIS_PRIVE
  ];

  const authCtx = useContext(AuthContext)

  const date = new Date()
  const dateString = `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDay().toString().padStart(2, '0')}-${date
    .getHours()
    .toString()
    .padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date
    .getSeconds()
    .toString()
    .padStart(2, '0')}`

  const [getOrdersByType, { loading }] = useLazyQuery(
    GET_DO_ORDER_BY_ORDER_TYPE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data: any) => {
        const validIds = data.getDOOrderByOrderType.edges.flatMap(
          ({ node: item }: { node: any }) => [item.order.id, item.order.key, item.calculators.cosiumId]
        )
        setInvalidOrderIds(orderIds.filter((i) => !validIds.includes(i)))
        const csvData = dataToCsv(JSON.parse(JSON.stringify(data)))
        setCsvData(csvData)
      },
    }
  )

  const dataToCsv = (data: any) => {
    let orderInfoCsvRow: { [key: string]: any } = {
      A: '',
      B: '',
      C: '',
      D: '',
      E: '',
      F: '',
      G: '',
      H: '',
      I: '',
      J: '',
      K: '',
      L: '',
      M: '',
      N: '',
      O: '',
      P: '',
      Q: '',
      R: '',
      S: '',
      T: '',
      U: '',
      V: '',
      W: '',
    }
    const csvData = data.getDOOrderByOrderType.edges.flatMap(
      ({ node: item }: { node: any }) => {
        const order = item.order
        const calculators = item.calculators
        if (deliveryModeKeys_2025.includes(order.deliveryMode.key)){
          setInvalidDeliveryOrders(prev => [...new Set([...prev, calculators.cosiumId || order.key])]);
          return []
        }
        const postalCode = order.deliveryAddress[0]?.postalCode

        orderInfoCsvRow['A'] =
          orderType === 'essdom'
            ? `Essai à domicile ${order.key}`
            : `Commande ${calculators.cosiumId?calculators.cosiumId:order.key}`
        orderInfoCsvRow['B'] = order.deliveryName[0]?.firstName?.toUpperCase()
        orderInfoCsvRow['C'] = order.deliveryName[0]?.lastName?.toUpperCase()
        orderInfoCsvRow['D'] = order.deliveryAddress[0]?.address?
          order.deliveryAddress[0]?.address.toUpperCase() : ''
        orderInfoCsvRow['E'] = order.deliveryAddress[0]?.additionalAddressDetails ? 
          order.deliveryAddress[0]?.additionalAddressDetails.toUpperCase() : ''
        orderInfoCsvRow['H'] = postalCode
        orderInfoCsvRow['I'] = order.deliveryAddress[0]?.city
        orderInfoCsvRow['J'] = order.deliveryAddress[0]?.country
        orderInfoCsvRow['K'] =
          order.deliveryAddress[0]?.mobilePhoneNumber ||
          order.deliveryAddress[0]?.landlinePhoneNumber

        orderInfoCsvRow['L'] = order.customer.login
        // poids du colis en kilogrammes (nb de produits * 100g ÷1000 pour le convertir en kg)
        orderInfoCsvRow['M'] = calculators.productCount ? (calculators.productCount * 0.1).toFixed(3) : ''

        // If available, the pickup point code must be added no matter the order type
        orderInfoCsvRow['O'] = order.pickupPointCode || ''

        if (orderType === 'essdom') {
          // Adding essdom return address
          orderInfoCsvRow = {
            ...orderInfoCsvRow,
            ...JSON.parse(authCtx.websiteSettings.essdomReturnAddress),
          }

          // each essdom order has two rows
          const essdomReturnCsvRow: { [key: string]: any } = JSON.parse(
            JSON.stringify(orderInfoCsvRow)
          )

          orderInfoCsvRow['N'] = 'BPR'
          essdomReturnCsvRow['N'] = 'CORE'
          essdomReturnCsvRow['O'] = ''

          return [
            Object.values(orderInfoCsvRow),
            Object.values(essdomReturnCsvRow),
          ]
        }

        if (orderType === 'panier') {
          orderInfoCsvRow['N'] = order.deliveryMode.key === constants.deliveryModeKeys.PICKUP_POINT ? 
            'BPR' : 'COLD'

          return [Object.values(orderInfoCsvRow)]
        }
        return null
      }
    )
    return csvData
  }

  const handleOrderTypeChange = (e: any) => {
    setInvalidOrderIds([])
    setInvalidDeliveryOrders([])
    setOrderType(e.target.value)
  }

  const handleOrderIdsChange = (e: any) => {
    setOrderIds(
      e.target.value
        .split('\n')
        .filter(Boolean)
        .map((str: any) => str.trim())
    )
  }

  const handleSubmit = (e: any) => {
    setCsvData([])
    e.preventDefault()
    getOrdersByType({
      variables: {
        orderType,
        orderIds: JSON.stringify(orderIds),
      },
    })
  }

  const renderAlert = (items:number[]|string[], variant:string, singularMessage:string, pluralMessage:string) => {
    if (items.length === 0) return null

    return (
      <Row style={{ marginTop: '10px' }}>
        <Col>
          <Alert variant={variant}>
            {items.length === 1 ? singularMessage : pluralMessage} {items.join(', ')}.
          </Alert>
        </Col>
      </Row>
    )
  }

  return (
    <Card>
      <Form>
        <fieldset>
          <Form.Group as={Row} className="mb-3">
            <Col sm={12}>
              <Form.Label as="legend" column sm={2}>
                Type de Commande :
              </Form.Label>
              <Form.Check
                inline
                type="radio"
                label="classique"
                name="orderType"
                defaultChecked={true}
                onChange={handleOrderTypeChange}
                value="panier"
                id="panier"
              />
              <Form.Check
                inline
                type="radio"
                label="essai à domicile"
                name="orderType"
                onChange={handleOrderTypeChange}
                value="essdom"
                id="essdom"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Liste des numéros de {orderType ==='panier'? 'devis Cosium' : 'commandes'} séparés par des sauts de ligne
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={handleOrderIdsChange}
              onKeyPress={(e) => {
                e.stopPropagation()
              }}
            />
          </Form.Group>
        </fieldset>
        <Button
          disabled={orderIds.length === 0||loading}
          type="button"
          onClick={handleSubmit}
        >
          {loading && (
            <Spinner
              variant="light"
              className="me-2"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Télécharger le CSV pour Coliship
        </Button>
      </Form>
      {csvData.length > 0 && !invalidOrderIds.length && (
        <CSVDownload
          data={csvData}
          enclosingCharacter=""
          separator={';'}
          filename={`${dateString}_${
            orderType === 'panier' ? 'expeditor' : 'essdom'
          }`}
          target="_self"
        />
      )}
      {renderAlert(
          invalidOrderIds,
          'danger',
          `La commande n'est pas de type "${orderType}" ou n'a pas été trouvée : `,
          `Les commandes ne sont pas de type "${orderType}" ou n'ont pas été trouvées : `
      )}

      {renderAlert(
        invalidDeliveryOrders,
        'warning',
        'La commande passe par un mode de livraison non compatible avec Coliship : ',
        'Les commandes passent par un mode de livraison non compatible avec Coliship : '
      )}
    </Card>
  )
}

export default ColishipCSV
