import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'

interface Props {
  template: string
  mailInfo: string
  closeModal?: () => void // sent from parent
}

const EventEmailPrevisualisationModal: React.FC<Props> = ({template, mailInfo, closeModal}) => {

  const { REACT_APP_PIMCORE_URL_DOMAIN } = process.env
  const [ iframeLoaded, setIframeLoaded] = useState<boolean>(false)
  const [ emailData, setEmailData] = useState<any>(null)

  const { id } = useParams<ParamType>()

  const GET_EMAIL_DATA = gql`
    query GET_EMAIL_DATA($id: Int!) {
      getEmailData(id: $id)
      {
        order_id
        order_key
        customer_name
        customer_firstname
        customer_civility
        email_address
      }
    }
  `

  const [getEmailData] = useLazyQuery(GET_EMAIL_DATA, {
    fetchPolicy: 'network-only', // Prevents not loading after first time
    variables: {
      id: +id,
    },
    onCompleted: (data) => {
      setEmailData(data.getEmailData)
      setTimeout(() => document.forms.namedItem('emailPreviewForm')?.submit()) // Prevents setState lag
    }
  })

  useEffect(() => {
    getEmailData()
  }, [getEmailData])
  
  return (
    <>
      <form name="emailPreviewForm" 
        target="email_preview" 
        action={`${REACT_APP_PIMCORE_URL_DOMAIN}${template}`}
        method="post">
        {emailData !== null && Object.keys(emailData).map((key) =>
          <input type="hidden" key={key} name={key} value={emailData[key]}/>
        )}
        <input type="hidden" key="mail_info" name="mail_info" value={mailInfo}/>
      </form>
      {!iframeLoaded && <div className="d-flex justify-content-center">En cours de chargement...</div>}
      <iframe title="email_preview"
        name="email_preview" 
        width="100%"
        height="600"
        onLoad={() => setIframeLoaded(true)}>
      </iframe>
    </>
  )

}

export default EventEmailPrevisualisationModal