import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-regular-svg-icons'

interface Props {
  children: any
  noTd?: boolean
  noSpace?: boolean
}

const CopyableCell: React.FC<Props> = ({ children, noTd, noSpace }) => {
  const [fadeStyle, setFadeStyle] = useState({})

  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text.toString()).then(() => {
      // Start fade effect
      setFadeStyle({ opacity: 1, transition: 'opacity 1s' })
      // Remove fade effect after 1 second
      setTimeout(
        () => setFadeStyle({ opacity: 0, transition: 'opacity 1s' }),
        100
      )
      // Reset style after the transition ends
      setTimeout(() => setFadeStyle({}), 500)
    })
  }

  const margin = noSpace ? '2px' : '10px'

  return (
    <>
      {!noTd && <td onClick={() => copyToClipboard(children)} style={{ cursor: 'pointer'}}>
        {children}
        <FontAwesomeIcon
          icon={faCopy}
          style={{ marginLeft: margin, ...fadeStyle }}
        />
      </td>}
      {!!noTd && <span onClick={() => copyToClipboard(children)} style={{ cursor: 'pointer', ...fadeStyle}}>
        {children}
        <FontAwesomeIcon
          icon={faCopy}
          style={{ marginLeft: margin }}
        />
        </span>}
    </>
  )
}

export default CopyableCell
