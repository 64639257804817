import { gql } from '@apollo/client'
import { DO_ACCESSORY_FIELDS } from 'graphql/fragments/DOAccessoryFields'
import { DO_FRAME_FIELDS } from 'graphql/fragments/DOFrameFields'

const BULK_UPDATE_DO_PRODUCT_STOCK = gql`
  ${DO_FRAME_FIELDS}
  ${DO_ACCESSORY_FIELDS}
  mutation bulkUpdateDOProductStock(
    $productDataJson: String!
    $stockMovementType:Int
    $comment: String
  ){
    bulkUpdateDOProductStock(
      productDataJson: $productDataJson
      stockMovementType: $stockMovementType
      comment: $comment
    ){
      success
      output {
        frames {
          ...DOFrameFields
        }
        accessories {
          ...DOAccessoryFields
        }
        failGtin
        existingStockLocations
      }
    }
  }
`

export default BULK_UPDATE_DO_PRODUCT_STOCK