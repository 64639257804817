import { gql } from '@apollo/client'

import { DO_CONTACT_NAME_FC_FIELDS } from 'graphql/fragments/DOContactNameFCFields'

import { DO_CONTACT_INFO_FC_FIELDS } from 'graphql/fragments/DOContactInfoFCFields'

export const GET_DO_CUSTOMER = gql`
  ${DO_CONTACT_NAME_FC_FIELDS}

  ${DO_CONTACT_INFO_FC_FIELDS}
  query GET_DO_CUSTOMER($id: Int) {
    getDOCustomer(id: $id) {
      contactName {
        ...DOContactNameFCFields
      }
      login
      contactInfo {
        ...DOContactInfoFCFields
      }
      id
      birthdate
      blacklisted
      newsletter
      creationDate
      siteDomain {
        ... on object_DOSite {
          domainSuffix
          id
        }
      }
      orders {
        ... on object_DOOrder {
          id
          key
          orderType
          creationDate
          modificationDate
          workflowState
          orderLines {
            ... on object_DOOrderLine {
              id
              prescription {
                ... on object_DOPrescription {
                  beneficiary {
                    name {
                      ...DOContactNameFCFields
                    }
                  }
                }
              }
            }
          }
          siteDomain {
            ... on object_DOSite {
              domainSuffix
            }
          }
        }
      }
      ordersOldSite {
        ... on object_DOOrderOldSite {
          creationDateOrderOldSite
          orderType
          orderCode
          beneficiaries
        }
      }
      blacklisted
      lastOrderDate
    }
  }
`
