import RightSideBar from 'components/RightSideBar/RightSideBar'
import OrderCard from 'components/OrderCard/OrderCard'
import OrderDetail from 'components/OrderDetail/OrderDetail'
import PrintCard from 'components/PrintCard'
import EventsTable from 'components/EventsTable'
import TableAccounting from 'components/TableAccounting'
import { useParams } from 'react-router-dom'
import Card from 'components/UI/Card'
import EventShortcutTabs from 'components/EventsShortcuts/EventShortcutTabs'
import SpecialEventShortcuts from 'components/SpecialEventShortcuts'
import { constants } from 'utils/constants'
import AuthContext from 'store/auth-context'
import { useContext } from 'react'
import SmsHandler from 'components/SmsHandler'

export default function GestionCommandeClient() {
  const { id } = useParams<ParamType>()
  const authCtx = useContext(AuthContext)

  const {
    ORDER_EVENT_TYPE_TO_REFUND,
    ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
    ORDER_EVENT_TYPE_ALERT,
    ORDER_EVENT_TYPE_ASSEMBLY_INFORMATION,
    ORDER_EVENT_TYPE_ORDER_TO_CREATE_IN_COSIUM,
    ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK,
  } = constants.eventTypeKeys

  const eventsInRightSideBar = [
    ORDER_EVENT_TYPE_ALERT,
    ORDER_EVENT_TYPE_ASSEMBLY_INFORMATION,
    ORDER_EVENT_TYPE_TO_REFUND,
    ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
    ORDER_EVENT_TYPE_ORDER_TO_CREATE_IN_COSIUM,
  ]

  if (
    authCtx.user.roles[0] === 'logistique_kehl' ||
    authCtx.user.fullName === 'admin'
  )
    eventsInRightSideBar.push(
      ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK
    )

  if (id == null) {
    return (
      <Card>
        <p>Aucune commande recherchée</p>
      </Card>
    )
  }

  //TODO : cas d'erreur sur les bénéficiaires
  return (
    <div className="d-flex justify-content-between position-relative">
      <div className="container-fluid px-0">
        <section className="row mb-4">
          <div className="col-12 col-lg-9 mb-4 mb-lg-0">
            <OrderCard />
          </div>
          <div className="col-12 col-lg-3">
            <PrintCard />
          </div>
        </section>
        <EventsTable />
        <OrderDetail />
        <EventShortcutTabs eventsInRightSideBar={eventsInRightSideBar} />
        <SpecialEventShortcuts />
        <SmsHandler />
        <TableAccounting />
      </div>

      <RightSideBar eventsInRightSideBar={eventsInRightSideBar} />
    </div>
  )
}
