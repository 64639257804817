import React from 'react'
import { getTokenFromLocalStorage } from '../../utils/functions'
import { Button, Form } from 'react-bootstrap'

interface Props {
    pimcoreDomainUrl: string,
    id: string,
    locale: string
}

const CustomerDirectConnect: React.FC<Props> = ({ pimcoreDomainUrl, id, locale }) => {
    const connectToFrontOfficeClickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (!window.confirm('Vous allez maintenant être connecté au compte de ce client. \nVeillez à vous en déconnecter une fois votre activité terminée.')) {
            return
        }
        document.forms.namedItem(`openCustomerPage_${locale}`)?.submit()
    }

    return (
        <Form 
            className="d-inline" 
            id={`openCustomerPage_${locale}`} 
            name={`openCustomerPage_${locale}`} 
            action={`${pimcoreDomainUrl}/directconnect`} 
            method="post" 
            target="_blank"
        >
            <input type="hidden" name="token" value={getTokenFromLocalStorage()} />
            <input type="hidden" name="id" value={id} />
            <Button type="submit" className="m-2" onClick={connectToFrontOfficeClickHandler}>
                Connexion au site {" "}
                <img
                    src={`/assets/images/${locale}.png`}
                    alt={`${locale} flag`}
                    style={{ width: '30px', height: '24px', marginRight: '5px' }}
                />
                en tant que ce client
            </Button>
        </Form>
    )
}

export default CustomerDirectConnect