import HeaderTitle from '../HeaderTitle'
import ModalAddress from '../Modal/ModalAddress'
import { HomeIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import DOModal from 'components/Modal/DOModal'
import DOButton from 'components/Modal/DOButton'
import { constants } from 'utils/constants'
import { GET_ORDER_DELIVERY_DETAILS } from 'graphql/queries/getOrderDeliveryDetails'
import './OrderCard.css'

const Address: React.FC = () => {
  const { id } = useParams<ParamType>()

  const [showAddressModal, setShowAddressModal] = useState(false)

  const { loading, error, data } = useQuery(GET_ORDER_DELIVERY_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: +id,
    },
  })

  if (loading) return <SkeletonBase variant="px-4" />
  if (error) return <p>`Error! ${error.message}`</p>

  const order = data.getDOOrder

  return (
    <div className="bg-white p-3 d-flex justify-content-between border-top border-bottom cursor-pointer"
      onClick={() => setShowAddressModal(true)}>
      <div className="d-flex flex-column">
        <HeaderTitle
          title="Adresse de livraison"
          children={<HomeIcon className="header-title-icon" />}
        />
        <div className="text-secondary">
          {order.deliveryMode
            ? (order.deliveryMode.key === constants.deliveryModeKeys.STORE && order.deliveryStore 
              ? <><div>Magasin : <strong>{order.deliveryStore.label}</strong></div>
                <div>{order.deliveryStore.contactInfo[0].address}
                {order.deliveryStore.contactInfo[0].additionalAddressDetails 
                ? ` ${order.deliveryStore.contactInfo[0].additionalAddressDetails} ` 
                : ''} {order.deliveryStore.contactInfo[0].postalCode} {order.deliveryStore.contactInfo[0].city}</div></> 
              : <>{order.deliveryMode.label}</>)
            : <>Mode de livraison : Non défini</>}
        </div>
      </div>
      <div>
        <div>
          <DOButton
            className="link-primary text-end"
            text="Afficher / Modifier l'adresse"
            handleClick={() => setShowAddressModal(true)}
          />
          <DOModal
            show={showAddressModal}
            customSize="modal-647"
            closeModal={() => setShowAddressModal(false)}
          >
            <ModalAddress order={order} />
          </DOModal>
        </div>
      </div>
    </div>
  )
}

export default Address
