import 'components/RightSideBar/RightSideBar.css'
import { Form } from 'react-bootstrap'
import { constants } from 'utils/constants'
import { getFormattedDateTime } from 'utils/functions'

interface Props {
  orderEvent: any
  onToggleClick?: (orderEventId: number) => void
  newItemIdAdded?: number
  noToggle?: boolean
}

const EventCard: React.FC<Props> = ({
  orderEvent,
  onToggleClick,
  newItemIdAdded,
  noToggle
}) => {
  const {
    ORDER_EVENT_TYPE_TO_REFUND,
    ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
    ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK,
  } = constants.eventTypeKeys

  const orderEventCreationDate = [
    ORDER_EVENT_TYPE_TO_REFUND,
    ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
  ].includes(orderEvent.eventType.key)
    ? ''
    : getFormattedDateTime(orderEvent.creationDate * 1000)

  const eventHighlight =
    newItemIdAdded === orderEvent.id ? 'event_highlight' : ''
  const backgroundColor = `${eventHighlight} ${
    constants.eventTypeToColor()[
      orderEvent.eventType.key as keyof typeof constants.eventTypeToColor
    ]
  }`

  let comment = orderEvent.comment

  if ([ORDER_EVENT_TYPE_TO_REFUND].includes(orderEvent.eventType.key)) {
    comment = `ATTENTION, Nous devons rembourser au client la somme de ${
      orderEvent.mailInfo || '-'
    } €`
  } else if (
    [ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA].includes(
      orderEvent.eventType.key
    )
  ) {
    comment = `ATTENTION, Avant de poursuivre la commande le client doit régler la somme de ${
      orderEvent.mailInfo || '-'
    } €`
  }
  const confirmMessage =
    orderEvent.eventType.key ===
    ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK
      ? `Êtes-vous sûr d’avoir physiquement remis en stock le produit ${comment} ?`
      : 'Voulez-vous vraiment fermer cet évènement ?'

  return (
    <>
      <div className={`${backgroundColor} px-3 py-1 small border-bottom`}>
        <div className="d-flex justify-content-between">
          <div className="d-flex justify-content-start align-items-start flex-column s-text ">
            {}

            <span className={`${backgroundColor} xxs-text`}>
              {orderEventCreationDate}
            </span>

            <span
              className={`${backgroundColor} word-space text-truncate text-wrap`}
              //TODO: régler la taille max de ce span au cas ou le texte est long
              style={{ maxWidth: '200px' }}
            >
              {comment}
            </span>
          </div>
          {!noToggle && onToggleClick && (
            <Form.Check
              type="switch"
              id={`event-${orderEvent.id}`}
              checked={true}
              onChange={() => {
                if (window.confirm(confirmMessage))
                  if (onToggleClick) onToggleClick(orderEvent.id)
              }}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default EventCard
