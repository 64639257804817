import React, { useState, useContext, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { ChatAltIcon } from '@heroicons/react/outline'
import { useParams } from 'react-router-dom'
import HeaderTitle from './HeaderTitle'
import { useMutation, useQuery } from '@apollo/client'
import { SEND_SMS } from 'graphql/mutations/sendSMSTwilio'
import GET_ORDER_EVENTS from 'graphql/queries/getDOOrderEvents';
import GET_ORDER_INFO from 'graphql/queries/getDOOrderInfo';
import { Link } from 'react-router-dom';
import { ToastContext } from 'store/toast-context'

const SmsHandler: React.FC = () => {
  const { id } = useParams<ParamType>()
  const [smsContent, setSmsContent] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [validatorError, setValidatorError] = useState<boolean>(false)
  const [link, setLink] = useState<string>('')
  const [apiError, setApiError] = useState<string | null>(null)
  
  const [sendSms, { loading: sendingMessage }] = useMutation(SEND_SMS)
  const { toast } = useContext(ToastContext)

  // Add this query to get order info including beneficiary contact info
  const { data: orderData } = useQuery(GET_ORDER_INFO, {
    variables: { id: +id },
    skip: !id
  })

  // Use useEffect to set the phone number when orderData changes
  useEffect(() => {
    let mobilePhoneNumber: string | undefined = orderData?.CQgetOrder?.edges?.[0]?.node?.order?.customer?.contactInfo?.[0].mobilePhoneNumber

    if (mobilePhoneNumber) {

      if (!isValidFrenchE164(mobilePhoneNumber)) {
        mobilePhoneNumber = parseToFrenchPhoneNumber(mobilePhoneNumber) || '';
      }

      if(!isValidFrenchE164(mobilePhoneNumber)){
        setValidatorError(true)
      }

      setPhoneNumber(mobilePhoneNumber)
    }
    setLink(`/bo/edition_client/${orderData?.CQgetOrder?.edges?.[0]?.node?.order?.customer?.id}`)
  }, [orderData])

  const parseToFrenchPhoneNumber = (phoneNumber: string | undefined): string | undefined => {
    if (!phoneNumber) return undefined;
    
    let cleaned = phoneNumber.replace(/[^0-9]/g, '');
    
    if (cleaned.startsWith('0')) {
      cleaned = cleaned.substring(1);
    }
    
    return cleaned.startsWith('+33') ? cleaned : `+33${cleaned}`;
  }

  const isValidFrenchE164 = (phoneNumber: string | undefined): boolean => {
    if (!phoneNumber) return false;
    
    const frenchE164Regex = /^\+33[1-9][0-9]{8}$/;
    return frenchE164Regex.test(phoneNumber);
  }

  const handleSendSms = async () => {
    if (!smsContent.trim()) return
    setApiError(null)
  
    try {
      if(isValidFrenchE164(phoneNumber)) {
        const response = await sendSms({
          refetchQueries: [GET_ORDER_EVENTS],
          awaitRefetchQueries: true,
          variables: {
            orderId: +id,
            message: smsContent,
            phoneNumber: phoneNumber
          },
        })
        
        if (response.data?.CMSendSMSTwilio?.success) {
          setSmsContent('')
          toast({
            title: 'SUCCÈS :',
            message: 'Le SMS a été envoyé avec succès.',
            variant: 'success'
          })
        } else {
          setApiError(response.data?.CMSendSMSTwilio?.output?.responseAPI || 'Une erreur est survenue lors de l\'envoi du SMS')
          toast({
            title: 'ERREUR :',
            message: 'Le SMS n\'a pas été envoyé.',
            variant: 'danger'
          })
        }
      }
    } catch (error: any) {
      setApiError(error.message || 'Une erreur est survenue lors de l\'envoi du SMS')
      toast({
        title: 'ERREUR :',
        message: 'Le SMS n\'a pas été envoyé.',
        variant: 'danger'
      })
    }
  }

  return (
    <div className="custom-card py-3 mb-4" id="sms-section">
      <div className="px-3">
        <HeaderTitle
          title="Envoyer un SMS"
          children={<ChatAltIcon className="header-title-icon" />}
        />
      </div>
      
      <div className="px-3">
        <div className="mb-3">
          <textarea
            className="form-control"
            rows={4}
            value={smsContent}
            onChange={(e) => setSmsContent(e.target.value)}
            placeholder="Saisissez votre message"
          />
          <div className="text-end mt-2">
            <small className="text-muted d-block">
              Caractères: {smsContent.length}/{smsContent.length ? (Math.ceil(smsContent.length / 160))*160 : 160}
            </small>
            <small className="text-muted d-block">
              SMS: {Math.ceil(smsContent.length / 160)}
            </small>
          </div>
        </div>
        
        <div className="text-end">
          <Button
            disabled={!smsContent.trim() || validatorError || apiError !== null}
            onClick={handleSendSms}
          >
            {sendingMessage ? 'Envoi en cours...' : 'Envoyer'}
          </Button>
        </div>

        {validatorError && (
          <div className="text-end mt-2">
            <small className="mb-0 text-danger">Le numéro de téléphone ( <Link to={link} style={{ fontSize: 'inherit' }}>{phoneNumber ?? 'inexistant'}</Link> ) n'est pas valide.</small>
          </div>
        )}

        {apiError && (
          <details className="text-end mt-2">
            <summary className="text-danger"><small className="mb-0">Une erreur est survenue lors de l'envoi du SMS</small></summary>
              <div className="start-end">
                <p className="text-danger mb-0 small">
                  {apiError}
                </p>
              </div>
          </details>
        )}
        
      </div>
    </div>
  )
}

export default SmsHandler 