import './RightSideBar.css'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import HeaderTitle from 'components/HeaderTitle'
import {
  BellIcon,
  ClipboardListIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  PencilAltIcon,
  PencilIcon,
  XCircleIcon,
} from '@heroicons/react/outline'
import {
  XCircleIcon as XCircleIconSolid,
  CheckCircleIcon,
} from '@heroicons/react/solid'

import { useQuery } from '@apollo/client'
import BadgeCard from './BadgeCard'
import EventList from './EventList'
import { Button } from 'react-bootstrap'
import { constants } from 'utils/constants'
import GET_ORDER_EVENTS from 'graphql/queries/getDOOrderEvents'
import EventCard from './EventCard'
import DOModal from 'components/Modal/DOModal'
import OrderCosiumAllInModal from 'components/Modal/OrderCosiumAllInModal'
import AuthContext from 'store/auth-context'
import MenuContext from 'store/menu-context'

interface Props {
  eventsInRightSideBar: string[]
}
const RightSideBar: React.FC<Props> = ({ eventsInRightSideBar }) => {
  const { id } = useParams<ParamType>()
  const authCtx = useContext(AuthContext)
  const menuContext = useContext(MenuContext)

  const {
    ORDER_EVENT_TYPE_TO_REFUND,
    ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
    ORDER_EVENT_TYPE_ALERT,
    ORDER_EVENT_TYPE_ASSEMBLY_INFORMATION,
    ORDER_EVENT_TYPE_ORDER_TO_CREATE_IN_COSIUM,
    ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK,
    ORDER_EVENT_TYPE_CUSTOMER_SERVICE_AFTERSALES_INFORMATION
  } = constants.eventTypeKeys

  // Overlay should only be displayed once (on first render) so we count the sidebar's number of rerenders
  const [sideBarDisplays, setSideBarDisplays] = useState<number>(0)
  const [showAddCosiumQuoteModal, setShowAddCosiumQuoteModal] = useState(false)
  const [displaySideBar, setDisplaySideBar] = useState<boolean>(false)

  const { loading, error, data } = useQuery(GET_ORDER_EVENTS, {
    variables: {
      id: +id,
    },
    onCompleted: (completedData) => {
      const displayRightSideBar: boolean =
        completedData.CQgetOrder.edges[0].node.order?.events.filter(
          (e: orderEvent) =>
            e.closureDate === null &&
            eventsInRightSideBar.includes(e.eventType?.key)
        ).length > 0
      setDisplaySideBar(displayRightSideBar)
      setSideBarDisplays((prevCount) => prevCount + 1)
    },
  })

  // In case the query was made on page load but the sidebar wasn't displayed
  // Increment the count so the overlay is not shown on the next automatic call
  if (!displaySideBar && sideBarDisplays === 1)
    setSideBarDisplays((prevCount) => prevCount + 1)

  const reportedInCosium = () => {
    const orderToCreateInCosiumEventList = order.events.filter(
      (e: orderEvent) =>
        e.eventType.key === ORDER_EVENT_TYPE_ORDER_TO_CREATE_IN_COSIUM
    )
    let hasOrderToCreateInCosiumEventOpen = orderToCreateInCosiumEventList
      .map((event: orderEvent) => {
        if (!event.closureDate) return false
        return true
      })
      .includes(false)

    const isReportedInCosium =
      orderToCreateInCosiumEventList.length > 0 &&
      !hasOrderToCreateInCosiumEventOpen

    return (
      <>
        {order.orderType === 'panier' &&
          orderToCreateInCosiumEventList.length > 0 && (
            <div className="mb-4">
              <HeaderTitle
                title="Report dans Cosium"
                children={
                  isReportedInCosium ? (
                    <CheckCircleIcon className="header-title-icon ps-3 text-success" />
                  ) : (
                    <XCircleIconSolid className="header-title-icon ps-3 text-danger" />
                  )
                }
              />

              <div
                data-cy="isReportedInCosium"
                className={`text-light text-center ${
                  isReportedInCosium ? 'bg-success' : 'bg-danger'
                } py-3`}
              >
                {isReportedInCosium
                  ? 'Le report a bien été fait dans Cosium'
                  : 'Le report dans Cosium doit être fait'}

                <div className="d-inline-block bg-white rounded mt-3">
                  <Button
                    data-cy="reportCosium"
                    variant="outline-primary p-1 px-3 btn-sm"
                    disabled={isReportedInCosium}
                    onClick={() => setShowAddCosiumQuoteModal(true)}
                  >
                    J'ai bien effectué le report
                  </Button>
                </div>

                <DOModal
                  show={showAddCosiumQuoteModal}
                  customSize="modal-647"
                  closeModal={() => {
                    setShowAddCosiumQuoteModal(false)
                  }}
                >
                  <OrderCosiumAllInModal
                    order={order}
                    calculators={data.CQgetOrder.edges[0].node.calculators}
                    isReportedInCosium={true}
                  />
                </DOModal>
              </div>
            </div>
          )}
      </>
    )
  }

  if (loading) return <div></div>
  if (error) return <p>`Error! ${error.message}`</p>

  const order = data.CQgetOrder.edges[0].node.order
  const hasNotification = !!order.events.filter(
    (e: orderEvent) =>
      [
        ORDER_EVENT_TYPE_TO_REFUND,
        ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
      ].includes(e.eventType?.key) && e.closureDate === null
  ).length
  const hasWIPProductWIPToPutPhysicallyBackToStock =
    !!order.events.filter(
      (e: orderEvent) =>
        e.eventType?.key ===
          ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK &&
        e.closureDate === null
    ).length &&
    (authCtx.user.roles[0] === 'logistique_kehl' ||
      authCtx.user.fullName === 'admin')

  return (
    <>
      <div
        className={displaySideBar && sideBarDisplays === 1 ? 'overlay' : ''}
        onClick={() => {
          setSideBarDisplays((prevCount) => prevCount + 1)
        }}
      ></div>

      {displaySideBar && (
        <div className="alert-width ">
          <div className="alert-bar">
            <div className="d-flex justify-content-end ps-3 py-3 align-items-center sticky-top bg-white">
              <BadgeCard />

              <XCircleIcon
                className="header-title-icon cursor custom-blue-link me-3"
                onClick={() => {
                  setDisplaySideBar(false)
                  // Ensures the overlay is not displayed afterwards when only the cache is updated (onCompleted is not fired)
                  setSideBarDisplays((prevCount) => prevCount + 1)
                }}
              />
            </div>
            {hasNotification && (
              <div className="notifications mb-4">
                <HeaderTitle
                  title="Notifications"
                  children={<BellIcon className="header-title-icon ps-3" />}
                />
                {order.events
                  .filter(
                    (e: orderEvent) =>
                      [
                        ORDER_EVENT_TYPE_TO_REFUND,
                        ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA,
                      ].includes(e.eventType?.key) && e.closureDate === null
                  )
                  .sort(
                    (eventA: orderEvent, eventB: orderEvent) =>
                      eventB.creationDate - eventA.creationDate
                  )
                  .map((event: orderEvent) => {
                    return (
                      <EventCard key={event.id} orderEvent={event}></EventCard>
                    )
                  })}
              </div>
            )}
            {reportedInCosium()}
            { <div className="comment mb-4">
              <HeaderTitle
                title="Commentaire"
                children={<PencilIcon className="header-title-icon ps-3" />}
              />
              <EventList
                displayEventList={false}
                eventTypeKeyFilter={ORDER_EVENT_TYPE_CUSTOMER_SERVICE_AFTERSALES_INFORMATION}
                events={order.events}
              />
            </div>}
            <div className="alertes mb-4">
              <HeaderTitle
                title="Alertes"
                children={
                  <ExclamationCircleIcon className="header-title-icon ps-3" />
                }
              />

              <EventList
                eventTypeKeyFilter={ORDER_EVENT_TYPE_ALERT}
                events={order.events}
              />
            </div>
            <div className="notes mb-4">
              <HeaderTitle
                title="Informations de montage"
                children={<PencilAltIcon className="header-title-icon ps-3" />}
              />
              <EventList
                eventTypeKeyFilter={ORDER_EVENT_TYPE_ASSEMBLY_INFORMATION}
                events={order.events}
              />
            </div>

            {hasWIPProductWIPToPutPhysicallyBackToStock && (
              <div className="notes mb-4">
                <HeaderTitle
                  title="Produits WIP à restocker"
                  children={
                    <ClipboardListIcon className="header-title-icon ps-3" />
                  }
                />
                <EventList
                  eventTypeKeyFilter={
                    ORDER_EVENT_TYPE_WIP_PRODUCT_TO_PUT_PHYSICALLY_BACK_TO_STOCK
                  }
                  events={order.events}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {!displaySideBar && (
        <div className="d-flex border-0 position-fixed p-2 end-0 bottom-0 zindex-2">
          <Button
            onClick={() => {
              setDisplaySideBar(true)
              menuContext.setSideBarNewEventCount(0)
              setSideBarDisplays((prevCount) => prevCount + 1)
            }}
            variant="warning"
            className={`btn-radius d-flex align-items-center alert-warning text-dark mt-1 me-4 mb-5 shadow alert-bar-button ${
              !!menuContext.sideBarNewEventCount
                ? 'alert-bar-button-hover-color'
                : ''
            }`}
          >
            <ExclamationIcon className="header-title-icon" />
            Infos annexes (
            {
              order.events.filter(
                (e: orderEvent) =>
                  e.closureDate === null &&
                  eventsInRightSideBar.includes(e.eventType?.key)
              ).length
            }
            )
          </Button>
          {!!menuContext.sideBarNewEventCount && (
            <div className="alert-bar-button-bubble">
              {menuContext.sideBarNewEventCount}
              <div className="bubble-left"></div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default RightSideBar
