import { useMutation } from '@apollo/client'
import BULK_CREATE_ORDER_EVENTS from 'graphql/mutations/bulkCreateOrderEvents'
import GET_ORDER_EVENTS from 'graphql/queries/getDOOrderEvents'
import React, { useContext, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { ToastContext } from 'store/toast-context'
import EventShortcutCheckbox from './EventShortcutCheckbox'
import MenuContext from 'store/menu-context'

interface Props {
  eventTypes: eventTypeType[]
  eventsInRightSideBar: string[]
}

const EventShortcutList: React.FC<Props> = ({
  eventTypes,
  eventsInRightSideBar,
}) => {
  const { id } = useParams<ParamType>()
  const { toast } = useContext(ToastContext)
  const { t } = useTranslation()
  const menuContext = useContext(MenuContext)

  const [eventsToSubmit, setEventsToSubmit] = useState<eventTypeType[]>([])

  const [bulkCreateOrderEvents, { loading }] = useMutation(
    BULK_CREATE_ORDER_EVENTS,
    {
      variables: {
        orderId: +id,
        events: JSON.stringify(eventsToSubmit),
      },
      refetchQueries: [
        // works only on success
        GET_ORDER_EVENTS,
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        if (
          data.bulkCreateOrderEvents.output.creationFailEventTypeKeys.length > 0
        ) {
          toast({
            title: 'Echec :',
            message: `${t('status_count_fail', {
              count:
                data.bulkCreateOrderEvents.output.creationFailEventTypeKeys
                  .length,
            })}`
          })
        }
        if (
          data.bulkCreateOrderEvents.output.creationSuccessEventTypeKeys
            .length > 0
        ) {
          toast({
            title: 'Succès :',
            message: `${t('status_count_success', {
              count:
                data.bulkCreateOrderEvents.output.creationSuccessEventTypeKeys
                  .length,
            })}`,
          })
        }

        // If an event added is in right side bar list, displays right side bar
        if (
          data.bulkCreateOrderEvents.output.creationSuccessEventTypeKeys.some(
            (e: string) => eventsInRightSideBar.indexOf(e) >= 0
          )
        ) {
          menuContext.setSideBarNewEventCount(++menuContext.sideBarNewEventCount)
        }

        setEventsToSubmit([])
      },
    }
  )

  const onUpdateHandler = (e: eventTypeType) => {
    setEventsToSubmit((prevState) => {
      let newState = [...prevState]
      let eventIndex = newState.findIndex((event) => event.key === e.key)
      // Si on trouve l'event on le met à jour ou on le retire de l'état. Si on le trouve pas, on l'ajoute
      if (eventIndex !== -1) {
        if (e.checked) {
          const { sentEmail, emailVariable, comment } = e.data
          const eventData = newState[eventIndex].data
          if (sentEmail !== undefined) eventData.sentEmail = sentEmail
          if (emailVariable) eventData.emailVariable = emailVariable
          if (comment) eventData.comment = comment
        } else {
          newState.splice(eventIndex, 1)
        }
      } else {
        newState.push(e)
      }
      return newState
    })
  }

  const checkboxes = eventTypes.map((eventType: eventTypeType) => {
    return (
      <EventShortcutCheckbox
        eventType={eventType}
        key={eventType.key}
        onUpdate={onUpdateHandler}
      />
    )
  })

  const callBulkCreateOrderEvents = async () => {
    try {
      await bulkCreateOrderEvents()
    } catch (e) {
      setEventsToSubmit([])
      // NB : gestion erreur dans App.tsx
    }
  }

  return (
    <>
      {!loading && checkboxes}
      <div style={{ float: 'right' }}>
        <Button
          variant="primary"
          onClick={() => {
            if (window.confirm('Êtes-vous sûr(e) ?')) {
              callBulkCreateOrderEvents()
            }
          }}
          disabled={eventsToSubmit.length === 0 || loading}
        >
          {loading && (
            <Spinner
              variant="light"
              className="me-2"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
          Enregistrer
        </Button>
      </div>
    </>
  )
}

export default EventShortcutList
