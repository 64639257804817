import { useMutation } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ToastContext } from 'store/toast-context'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'
import { CM_DELETE_PODUCT } from 'graphql/mutations/deleteProduct'
import EditProductSearchForm from './EditProductSearchForm'

interface Props {
  item: any
  closeModal?: () => void
  orderLine: orderLineType
}

const EditAccessoryModal: React.FC<Props> = ({ item, closeModal, orderLine }) => {
  const { t } = useTranslation()
  const { REACT_APP_PIMCORE_URL_DOMAIN } = process.env
  const { toast } = useContext(ToastContext)

  const [selectedRadio, setSelectedRadio] = useState('')
  const [awaitingConfirmation, setAwaitingConfirmation] = useState(false)
  
  let product: any = {}
  let accessory: any
  let imagesUrlsT: any = ''
  if (item.item[0].__typename === 'fieldcollection_DOOLItemAccessoryFC') {
    accessory = item.item[0].accessory[0]
    if (accessory) {
      product.brand = accessory?.brand?.name
      imagesUrlsT = JSON.parse(accessory?.imageUrls)
      if (accessory?.images || imagesUrlsT?.length) {
        product.image = accessory.images
          ? `${REACT_APP_PIMCORE_URL_DOMAIN}` + accessory.images[0]?.image?.fullpath
          : imagesUrlsT.find((obj: any) => obj.order === 1).url
      }
      
      product.color = accessory?.color?.label
      product.category = accessory?.category?.parentCategory?.label + ' '+(t(accessory.category?.rim))
      product.name = accessory?.name
      product.ean = accessory?.gtin
      product.sku = accessory?.sku
      product.idmc = accessory?.idmc
      product.id = accessory.id
    }
  } 

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadio(e.target.value)
    setAwaitingConfirmation(false)
  }

  const deleteAccessoryHandler = async () => {
    try {
      if (awaitingConfirmation) {
        await deleteProduct({
          refetchQueries: [CQ_GET_ORDER],
          awaitRefetchQueries: true,
          variables: { orderLineItemId: +orderLine.items[0].id },
        })
      }
      setAwaitingConfirmation(!awaitingConfirmation)
    } catch (error) {}
  }

  const [deleteProduct, { loading }] = useMutation(CM_DELETE_PODUCT, {
    onCompleted: (data) => {
      if (closeModal) closeModal()
      const errorMessage = data?.deleteAccessory?.output?.errorMessage as string
      toast({
        title : errorMessage ? 'Erreur :' : 'Succès :',
        message: errorMessage || 'Suppression de l\'accessoire enregistrée'
      })
    }
  })

  //prevents warning in console after adding delete accessory because we want to close unmounted componant
    useEffect(() => {
      return () => {
        if (awaitingConfirmation) setAwaitingConfirmation(false)
      }
    }, [awaitingConfirmation, setAwaitingConfirmation])

  return (
    <div>
      <div className="d-flex">
        {product.image && (
          <img
            src={product.image}
            alt={product.brand}
            className="brand-model-image"
            style={{
              height: '100%',
              width: '140px',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
          />
        )}
        <Container>
          <Row>
            <Col xs={accessory?3:4}>
              <b>{accessory ? 'Marque' : 'Type de monture'}</b>
            </Col>
            <Col>
              {accessory
                ? product.brand 
                : `Monture ${product?.category?.toLowerCase()}`}
            </Col>
          </Row>      
          {accessory && (
            <>
              <Row>
                <Col xs={3}>
                  <b>Modèle Couleur</b>
                </Col>
                <Col>
                  {product.name} {product.color}
                </Col>
              </Row>
               <Row>
                <Col xs={3}>
                  <b>Catégorie</b>
                </Col>
                <Col>
                  {product.category}
                </Col>
              </Row>
               <Row>
                <Col xs={3}>
                  <b>EAN</b>
                </Col>
                <Col>
                  {product.ean}
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <b>SKU</b>
                </Col>
                <Col>
                  {product.sku}
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <b>IDMC</b>
                </Col>
                <Col>
                  {product.idmc}
                </Col>
              </Row>
              <Row>
                <Col xs={3}>
                  <b>ID</b>
                </Col>
                <Col>
                  {product.id}
                </Col>
              </Row>
            </>
          )}
          <Row className="mt-2 mb-3">
            <Col>
              <b>Prix unitaire</b> {(item.unitAmount.value).toFixed(2)} €
            </Col>
            <Col>
              <b>Quantité </b>x{item.quantity}
            </Col>
            <Col>
              <b>Total </b>
              {(item.unitAmount.value * item.quantity).toFixed(2)} €
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="mt-3">
        <Row>
          <Col xs={2}>Voulez-vous : </Col>
          <Col className="mb-3">
            <fieldset>
              <Form.Group controlId={'input' + item.id}>
                <Row>
                  <Form.Check
                    inline
                    label="Modifier par un accessoire"
                    name="editAccessory"
                    type="radio"
                    value="accessory"
                    id="editAccessory"
                    onChange={(e) => handleSelect(e)}
                  />
                  <Form.Check
                    inline
                    label="Supprimer l'accessoire"
                    name="editAccessory"
                    type="radio"
                    value="deleteAccessory"
                    id="deleteAccessory"
                    onChange={(e) => handleSelect(e)}
                  />
                </Row>
              </Form.Group>
            </fieldset>
          </Col>
          {(selectedRadio === 'frame' || selectedRadio==='accessory' || selectedRadio === 'editLenses') && (
            <EditProductSearchForm
              orderLineItem={item}
              selectedRadio={selectedRadio}
              closeModal={closeModal}
            />
          )}
          {selectedRadio === 'deleteAccessory' && (
            <div>
              <div className="d-flex justify-content-center">
                {awaitingConfirmation && (
                  <h4>
                    Êtes-vous sûr de supprimer l'accessoire ?
                  </h4>
                )}
              </div>

              <div className="mt-3 text-center">
                <Button
                  className={`m-2 btn ${
                    awaitingConfirmation ? 'btn-success' : 'btn-primary'
                  }`}
                  disabled={loading}
                  onClick={deleteAccessoryHandler}
                >
                  {loading && (
                    <Spinner
                      variant="light"
                      className="me-2"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {awaitingConfirmation ? 'Confirmer' : 'Enregistrer'}
                </Button>

                <Button
                  variant="outline-primary bg-white text-primary"
                  className="m-2"
                  disabled={loading}
                  onClick={() => {
                    if (awaitingConfirmation) setAwaitingConfirmation(false)
                    else if (closeModal) closeModal()
                  }}
                >
                  Annuler
                </Button>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default EditAccessoryModal
