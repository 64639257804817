import { gql } from '@apollo/client'

export const SEND_SMS = gql`
  mutation SEND_SMS($phoneNumber: String!, $message: String!, $orderId: Int) {
    CMSendSMSTwilio(input: { phoneNumber: $phoneNumber, message: $message, orderId: $orderId }) {
      success
      output{
        success
        responseAPI
      }
    }
  }
` 