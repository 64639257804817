import React, { SetStateAction, useState, useContext } from 'react'
import { Button, Form, Spinner, Row, Col, Tab, Tabs} from 'react-bootstrap'
import { Tooltip } from 'react-tooltip'
import Card from 'components/UI/Card'
import { GET_DO_PRODUCT_STOCK } from 'graphql/queries/getDOProductStock'
import MODIFY_DO_PRODUCT_STOCK from 'graphql/mutations/modifyDOProductStock'
import UPDATE_DO_PRODUCT_STOCK_LOCATION from 'graphql/mutations/updateDOProductStockLocation'
import UPDATE_DO_PRODUCT_STOCK_THRESHOLD from 'graphql/mutations/updateDOProductStockThreshold'
import { useLazyQuery, useMutation } from '@apollo/client'
import { validateNumberInput } from 'utils/functions'
import { ToastContext } from 'store/toast-context'
import GestionBatch from './GestionBatch'
import TypeaheadInput from 'components/UI/TypeaheadInput'

const GestionStock: React.FC = () => {
  const { toast } = useContext(ToastContext)

  const MIN_LENGTH_SEARCH = 3
  const [eanCode, setEanCode] = useState<string>('')
  const [stockLocation, setStockLocation] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [quantity, setQuantity] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [transactionType, setTransactionType] = useState<1 | 2>(1)
  const [productData, setProductData] = useState<frameType | accessoryType | null>(null)
  const [newStockLocation, setNewStockLocation] = useState<string>('')
  const [newSalesThreshold, setNewSalesThreshold] = useState<string>('')
  const [newHometrialThreshold, setNewHometrialThreshold] = useState<string>('')
  const [idmcList, setIdmcList] = useState<Array<number>>([])
  const [key, setKey] = useState("unit")
  const [optionsTypeahead, setOptionsTypeahead] = useState<productNameColorType[]>([])
  const [isSearch, setIsSearch] = useState<boolean>(false)
  const [idProductV4, setIdProductV4] = useState<string>('')
  const [existingStockLocation, setExistingStockLocation] = useState<boolean>(false)

  const [getDOProductStock, { loading: productLoading, data: stockData }] = useLazyQuery(GET_DO_PRODUCT_STOCK, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
        setIsSearch(true)
        if (stockData.getDOProductStock.edges.length === 1) {
          const product = stockData.getDOProductStock.edges[0].node.frame??stockData.getDOProductStock.edges[0].node.accessory
          setProductData(product)
          setNewStockLocation(product?.stockLocation)
          setIdmcList(stockData.getDOProductStock.edges[0].node.idmcList)
          setIdProductV4(stockData.getDOProductStock.edges[0].node.idProductV4)
          setOptionsTypeahead([])
          setIsSearch(false)
        }
        else if (stockData.getDOProductStock.edges.length > 1 && name !== ''){
          searchDOProductStockByName(name)
        }
        else{
          toast({
            title: 'Echec :',
            message: 'Aucun résultat pour les données saisies.'
          })
          setOptionsTypeahead([])
        }
    },
  })

  const handleClick = (gtin: string) => {
    navigator.clipboard.writeText(gtin).then(() => {
      toast({
        title: 'Réussi :',
        message: 'Texte copié.'
      })
    });
  }

  const searchDOProductStockByName = (queryName : string) =>  {
    setIsSearch(false)
    let doProductEdge = stockData.getDOProductStock.edges.filter((edge: any) => (edge.node.frame ? 
        edge.node.frame.name.toLowerCase() : edge.node.accessory.name.toLowerCase()
      ).includes(queryName.toLowerCase()))

    if (doProductEdge.length > 1){
      setOptionsTypeahead(doProductEdge.map((edge: any) => 
        {
          const product = edge.node.frame??edge.node.accessory
          return { 
                  productName: product.name,
                  color: { label: product.color.label }
                }
        }))
    }
    else if (doProductEdge.length === 1){
      const product = doProductEdge[0].node.frame??doProductEdge[0].node.accessory
      setProductData(product)
      setNewStockLocation(product.stockLocation)
      setIdmcList(doProductEdge[0].node.idmcList)
      setIdProductV4(stockData.getDOProductStock.edges[0].node.idProductV4)
      setOptionsTypeahead([])
    }
    else{
      toast({
        title: 'Echec :',
        message: 'Aucun résultat pour les données saisies.'
      })
      setOptionsTypeahead([])
    }
  }

  const selectDOProductStockByName  = (queryName : string, color : string) =>  {
    setIsSearch(false)
    const doProductEdge = stockData.getDOProductStock.edges.filter((edge: any) => edge.node.frame ?
        edge.node.frame.name === queryName : edge.node.accessory.name === queryName)
    const doProduct = doProductEdge.find((edge: any) => edge.node.frame ?
        edge.node.frame.color.label === color : edge.node.accessory.color.label === color)
    const product = doProduct.node.frame??doProduct.node.accessory
    setProductData(product)
    setNewStockLocation(product.stockLocation)
    setIdmcList(doProduct.node.idmcList)
    setIdProductV4(stockData.getDOProductStock.edges[0].node.idProductV4)
    setOptionsTypeahead([])
  }

  const handleSearch = () => {
    eraseAll()
    getDOProductStock({
      variables: { eanOrIdmcList: [eanCode], stockLocation: stockLocation, name: '' }
    })
  }

  const handleNameSearch = (queryName : string) => {
    if ((queryName.length === MIN_LENGTH_SEARCH && !name.includes(queryName)) || (queryName.length > MIN_LENGTH_SEARCH && isSearch === false)) {
      getDOProductStock({
        variables: { eanOrIdmcList: [''], stockLocation: '', name: queryName}
      })
    }
    else if (queryName.length >= MIN_LENGTH_SEARCH && stockData !== undefined){
      searchDOProductStockByName(queryName)
    }
    setName(queryName)
  }

  const onClickOption = (option: productNameColorType) => {
    setName(option.productName)
    setOptionsTypeahead([])
    eraseAll()

    selectDOProductStockByName(option.productName, option.color!.label)
  }

  const eraseAll = () => {
    setQuantity('')
    setComment('')
    setNewStockLocation('')
    setNewSalesThreshold('')
    setNewHometrialThreshold('')
  }

  const [ModifyDOProductStock, { loading: loadingModifyStock }] = useMutation(MODIFY_DO_PRODUCT_STOCK, {
    onCompleted: (mutationData) => {
      if(mutationData.modifyDOProductStock.output.frame?.key || mutationData.modifyDOProductStock.output.accessory?.key)
      {
        const product = mutationData.modifyDOProductStock.output.frame??mutationData.modifyDOProductStock.output.accessory
        setProductData(product)
        setTransactionType(1)
        setQuantity('')
        setComment('')

        toast({
          title: 'Succès :',
          message: 'Nouvelle ' + (transactionType === 1 ? 'entrée' : 'sortie') + ' de stock enregistrée'
        })
      }
    },
  })

  const handleUpdateStock = () => {
    ModifyDOProductStock({
      variables: {
        productGtin: productData?.gtin,
        quantity: transactionType === 1 ? parseInt(quantity) : -parseInt(quantity),
        // quantity: 1,
        comment: comment
      },
    })
  }

  const [updateDOProductStockLocation, { loading: loadingStockLocation }] = useMutation(UPDATE_DO_PRODUCT_STOCK_LOCATION, {
    onCompleted: (mutationData) => {
      if(mutationData.updateDOProductStockLocation.output.frame?.key || mutationData.updateDOProductStockLocation.output.accessory?.key)
      {
        const product = mutationData.updateDOProductStockLocation.output.frame??mutationData.updateDOProductStockLocation.output.accessory
        setProductData(product)
        setExistingStockLocation(mutationData.updateDOProductStockLocation.output.exists)

        if(!mutationData.updateDOProductStockLocation.output.exists)
        {
          setNewStockLocation(product.stockLocation)
          setStockLocation('')
          toast({
            title: 'Succès :',
            message: 'Nouvel emplacement enregistré'
          })
        }
      }
    }
  })

  const handleUpdateStockLocation = () => {
    updateDOProductStockLocation({
      variables: {
        id: productData ? +productData.id : 0,
        stockLocation: newStockLocation,
        force: existingStockLocation
      }
    })
  }

  const cancelUpdateStockLocation = () => {
    setNewStockLocation(productData ? productData.stockLocation : '')
    setExistingStockLocation(false)
  }

  const [updateDOProductStockThreshold, {loading: loadingStockThreshold }] = useMutation(UPDATE_DO_PRODUCT_STOCK_THRESHOLD, {
    onCompleted: (mutationData) => {
      if(mutationData.updateDOProductStockThreshold.output.frame?.key || mutationData.updateDOProductStockThreshold.output.accessory?.key)
      {
        const product = mutationData.updateDOProductStockThreshold.output.frame??mutationData.updateDOProductStockThreshold.output.accessory
        setProductData(product)
        setNewSalesThreshold('')
        setNewHometrialThreshold('')
        toast({
          title: 'Succès :',
          message: 'Nouveau(x) seuil(s) enregistré(s)'
        })
      }
    }
  })

  const handleUpdateStockThreshold = () => {
    updateDOProductStockThreshold({
      variables: {
        gtin: productData?.gtin,
        salesThreshold: newSalesThreshold,
        hometrialThreshold: newHometrialThreshold
      }
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>, value: string) => {
    if(!(productLoading || (value.length === 0)) && e.key === 'Enter')
      handleSearch()
  }

  let stockEnBac = 0
  let stockDispo = 0
  let stockEssdom = 0
  if (productData && productData.key) {
    const stockInfo =
      productData.stock[0]
    stockEnBac =
      stockInfo.totalQuantity - stockInfo.wipQuantity - stockInfo.essdomQuantity
    //stockDispo = En bac kehl + Chez BBGR - seuil de vente - stock réservé 
    stockDispo = stockEnBac + (productData.stock[0].bbgrQuantity??0) - (productData.stock[0].salesThreshold ?? 0) - productData.stock[0].reservedQuantity
    stockEssdom = 
      stockInfo.totalQuantity - stockInfo.reservedQuantity - stockInfo.wipQuantity - stockInfo.essdomQuantity - stockInfo.homeTrialThreshold
  }

  return (
    <Card>
      <Tabs
        id="controlled-tab-picking"
        activeKey={key}
        onSelect={(k) => setKey(k as SetStateAction<string>)}
        className="mb-3"
      >
        <Tab
          eventKey="unit"
          title="Par produit"
          key={`unit ${key === "unit" ? 'active' : 'inactive'}`}
        >
          <Form>
            <Row>
              <Form.Group className="mb-2">
                <Row>
                  <Col sm={6}>
                    <Form.Label>Recherche d'un stock par code EAN, IMDC ou nouvel ID</Form.Label>
                      <Form.Control
                        type="text"
                        value={eanCode}
                        onChange={(e) => setEanCode(e.target.value)}
                        className="me-3"
                        onKeyDown={(e) => handleKeyDown(e, eanCode)}
                      />
                  </Col>
                  <Col sm={6}>
                    <Form.Label>Recherche d'un stock par emplacement</Form.Label>
                      <Form.Control
                        type="text"
                        value={stockLocation}
                        onChange={(e) => {
                          setStockLocation(e.target.value)
                          setEanCode('')
                          setName('')
                        }}
                        className="me-3"
                        onKeyDown={(e) => handleKeyDown(e, stockLocation)}
                      />
                  </Col>       
                </Row>
                <Row className='mt-3'>
                  <Col sm={6}>
                    <Form.Label>Recherche d'un stock par nom</Form.Label>
                      <TypeaheadInput
                        options={optionsTypeahead}
                        onSearch={handleNameSearch}
                        minLength={MIN_LENGTH_SEARCH}
                        onClickOption={onClickOption}
                        placeholder="Pour activer l'autocomplétion, veuillez saisir au moins 3 caractères."
                      />
                  </Col>
                  <Col sm={6} className='d-flex align-self-end justify-content-end'>
                    <Button
                      onClick={handleSearch}
                      disabled={productLoading 
                        || loadingStockLocation 
                        || loadingModifyStock 
                        || loadingStockThreshold 
                        || (eanCode.length + stockLocation.length + name.length === 0)}
                      variant="primary shadow zindex-2">
                      {productLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="light"
                        />
                      ) : 'Rechercher'}
                    </Button>
                  </Col>
                  </Row>
              </Form.Group>
            </Row>
            {productData && (
              <>
              {!productData.key && (
              <p
                style={{ color: 'red', fontSize: '12px', marginBottom: '15px' }}
              >
                {' '}
                {`L’EAN ou l'emplacement saisi n'existe pas dans la base de données.`}{' '}
              </p>
              )}
              {productData.key && (
              <>
                <Row className="mt-4 border-bottom"></Row>
                <Row className="mt-3 mb-4">
                  <Col xs={4}>
                    <Form.Label>Emplacement de stock</Form.Label>
                    <div className="d-flex align-items-left justify-content-left">
                      <Form.Control
                        type="text"
                        value={newStockLocation}
                        onChange={(e) => setNewStockLocation(e.target.value)}
                        className="me-3 "
                      />
                    </div>
                  </Col>
                  <Col style={{ alignSelf: 'flex-end'}}>
                  {!existingStockLocation && <Button 
                      onClick={handleUpdateStockLocation} 
                      disabled={productLoading 
                        || loadingStockLocation 
                        || newStockLocation?.length === 0
                        || newStockLocation === productData.stockLocation}
                      variant="primary shadow zindex-2">
                      {loadingStockLocation ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="light"
                        />
                      ) : 'Modifier'}
                    </Button>}
                    {!!existingStockLocation && <>
                    <Button 
                      className="me-3"
                      onClick={cancelUpdateStockLocation} 
                      variant="secondary shadow zindex-2">Annuler
                    </Button>
                    <Button 
                      onClick={handleUpdateStockLocation} 
                      variant="primary shadow zindex-2">
                      {loadingStockLocation ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="light"
                        />
                      ) : 'Confirmer'}
                    </Button>
                    </>}
                    
                  </Col>
                  {!!existingStockLocation && <div className="text-danger">Emplacement déjà existant.</div>}
                </Row>
                <Row>
                  <Form.Group className="mb-3 d-flex justify-content-left">
                    {/* Flex container for side-by-side */}
                    <Form.Label className="me-5 fw-bold">
                      {/* Highlighted and with right margin */}
                      <span className="text-nowrap">EAN :</span>
                      <br/>
                      <>
                        <Tooltip 
                          content="Copier" 
                          id="tooltip-ean"
                          place="bottom"
                        />
                        <span className="badge rounded-pill cursor border border-info text-info" onClick={() => handleClick(productData.gtin)} data-tooltip-id={'tooltip-ean'}> {productData.gtin}</span>
                      </>
                    </Form.Label>
                    <Form.Label className="me-5 fw-bold">
                      {/* Highlighted and with right margin */}
                      <span className="text-nowrap">Anciens IDMC :</span>
                      <br/>
                      <span className="text-primary"> {idmcList.length ? idmcList : 'AUCUN'}</span>
                    </Form.Label>
                    <Form.Label className="me-5 fw-bold">
                      {/* Highlighted and with right margin */}
                      <span className="text-nowrap">Nouvel IDMC :</span>
                      <br/>
                      <span className="text-primary">{idProductV4 ?? 'AUCUN'}</span>
                    </Form.Label>
                    <Form.Label className="me-5 fw-bold">
                      {/* Highlighted and with right margin */}
                      <span className="text-nowrap">Nom produit :</span>
                      <br/>
                      <span className="text-primary"> {[productData.name,productData.color.label].join(' ')}</span>
                    </Form.Label>
                  </Form.Group>
                </Row>
                {/* <Row><Col className="fw-bold text-center mb-2">--- STOCK ---</Col></Row> */}
                <Row>
                  <Form.Group className="d-flex justify-content-center">
                    <Form.Label className="me-3 fw-bold">
                      {/* Highlighted and with right margin */}
                      En bac kehl :
                      <span className="text-primary"> {stockEnBac}</span>
                    </Form.Label>
                    <Form.Label className="me-3 fw-bold">
                      {/* Highlighted and with right margin */}
                      Chez BBGR :
                      <span className="text-primary"> {productData.stock[0].bbgrQuantity}</span>
                    </Form.Label>
                    <Form.Label className="me-3 fw-bold">
                      {/* Highlighted */}
                      Dispo vente : 
                      <span className="text-primary"> {stockDispo}</span>
                    </Form.Label>
                    <Form.Label className="me-3 fw-bold">
                      {/* Highlighted */}
                      Dispo essdom : 
                      <span className="text-primary"> {stockEssdom}</span>
                    </Form.Label>
                    <Form.Label className="me-3 fw-bold">
                      {/* Highlighted */}
                      Réservé : 
                      <span className="text-primary"> {productData.stock[0].reservedQuantity}</span>
                    </Form.Label>
                    <Form.Label className="me-3 fw-bold">
                      {/* Highlighted */}
                      Work in progress : 
                      <span className="text-primary"> {productData.stock[0].wipQuantity}</span>
                    </Form.Label>
                    <Form.Label className="me-3 fw-bold">
                      {/* Highlighted */}
                      Chez le client : 
                      <span className="text-primary"> {productData.stock[0].essdomQuantity}</span>
                    </Form.Label>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3 d-flex justify-content-center">
                    <Form.Label className="me-4 fw-bold">
                      {/* Highlighted and with right margin */}
                      Seuil de vente :
                      <span className="text-danger"> {productData.stock[0].salesThreshold ?? 0}</span>
                    </Form.Label>
                    <Form.Label className="me-4 fw-bold">
                      {/* Highlighted */}
                      Seuil essdom : 
                      <span className="text-danger"> {productData.stock[0].homeTrialThreshold ?? 0}</span>
                    </Form.Label>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      label="Entrée"
                      type="radio"
                      name="transactionType"
                      checked={transactionType === 1}
                      onChange={() => setTransactionType(1)}
                    />
                    <Form.Check
                      inline
                      label="Sortie"
                      type="radio"
                      name="transactionType"
                      checked={transactionType === 2}
                      onChange={() => setTransactionType(2)}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                    {/* Flex container for side-by-side */}
                    <Col xs={2}>
                      {/* With right margin and grow as needed */}
                      <Form.Label>Quantité</Form.Label>
                      <Form.Control
                        type="text"
                        value={quantity}
                        onChange={(e) => setQuantity(validateNumberInput(e.target.value))}
                      />
                    </Col>
                    <Col>
                      {/* Grow as needed */}
                      <Form.Label>Commentaire</Form.Label>
                      <Form.Control
                        type="text"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </Col>
                    <Col xs={2} style={{ alignSelf: 'flex-end'}}>
                    <Button 
                      onClick={handleUpdateStock}
                      disabled={productLoading 
                        || loadingModifyStock 
                        || quantity.length === 0}
                      variant="primary shadow zindex-2">
                      {loadingModifyStock ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="light"
                        />
                      ) : 'Modifier'}
                    </Button>
                    </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col>
                        <Form.Label>Nouveau seuil de vente</Form.Label>
                        <div className="d-flex align-items-left justify-content-left">
                          <Form.Control
                            type="text"
                            value={newSalesThreshold}
                            onChange={(e) => setNewSalesThreshold(validateNumberInput(e.target.value))}
                            className="me-3 "
                          />
                        </div>
                      </Col>
                      <Col>
                        <Form.Label>Nouveau seuil essdom</Form.Label>
                        <div className="d-flex align-items-left justify-content-left">
                          <Form.Control
                            type="text"
                            value={newHometrialThreshold}
                            onChange={(e) => setNewHometrialThreshold(validateNumberInput(e.target.value))}
                            className="me-3 "
                          />
                        </div>
                      </Col>
                      <Col xs={2} style={{ alignSelf: 'flex-end'}}>
                        <Button 
                          onClick={handleUpdateStockThreshold} 
                          disabled={productLoading 
                            || loadingStockThreshold 
                            || (newSalesThreshold.length + newHometrialThreshold.length === 0)}
                          variant="primary shadow zindex-2">
                          {loadingStockThreshold ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              variant="light"
                            />
                          ) : 'Modifier'}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
              </>
            )}</>)}
          </Form>
        </Tab>
         <Tab
          eventKey="batch"
          title="En batch"
          key={`batch ${key === "batch" ? 'active' : 'inactive'}`}
        >
          <GestionBatch/>
        </Tab>
      </Tabs>
    </Card>
  )
}

export default GestionStock
