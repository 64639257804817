import { useMutation, useQuery } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { Accordion, Button, Form, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Card from 'components/UI/Card'
import { GET_DO_CUSTOMER } from 'graphql/queries/getDOCustomer'
import { GET_COUNTRIES_LISTING } from 'graphql/queries/getCountriesListing'
import useForm from 'components/InputValidations/useForm'
import validateInfo from 'components/InputValidations/validateInfo'
import InputCustom from 'components/InputValidations/InputCustom'
import { ToastContext } from 'store/toast-context'
import UPDATE_DO_CUSTOMER from 'graphql/mutations/updateDOCustomer'
import { GET_DO_SITE_LISTING } from 'graphql/queries/getDOSiteListing'
import { useTranslation } from 'react-i18next'
import { CheckIcon } from '@heroicons/react/solid'
import Skeleton from 'react-loading-skeleton'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import ToggleCustomerVouchers from './ToggleCustomerVouchers'
import OrderHistoryTable from 'components/OrderCard/OrderHistoryTable'
import './EditCustomer.css'
import GET_ORDER_INFO from 'graphql/queries/getDOOrderInfo';
import CustomerDirectConnect from 'components/EditCustomer/CustomerDirectConnect'

interface Props {}
const EditCustomer: React.FC<Props> = () => {
  const {
    handleChange,
    handleChangeSelect,
    handleGeneratePassword,
    handleCopyPassword,
    values,
    handleSubmit,
    errors,
    setValues,
    handleFocusOut,
    handleFocusOutSelect,
    isFocusOut,
    setIsFocusOut,
    isFocusOutAndSetErrors,
  } = useForm(validateInfo, [
    'firstName',
    'lastName',
    'email',
    'landlinePhoneNumber',
    'mobilePhoneNumber',
    'postalCode',
    'birthdate',
    'password'
  ])

  const {REACT_APP_PIMCORE_URL_DOMAIN, REACT_APP_PIMCORE_URL_DOMAIN_it, REACT_APP_PIMCORE_URL_DOMAIN_es} = process.env

  const { id } = useParams<ParamType>()
  const { t } = useTranslation()
  const { toast } = useContext(ToastContext)

  //DOSiteListing query for select language
  const { loading: doSiteListingLoading, data: doSiteListingData } =
    useQuery(GET_DO_SITE_LISTING)

  //CountriesListing query for select country
  const { loading: countriesListingLoading, data: countriesListingData } =
    useQuery(GET_COUNTRIES_LISTING)

  const {
    error: customerError,
    loading: customerLoading,
    data: customerData,
  } = useQuery(GET_DO_CUSTOMER, {
    variables: { id: +id },
    skip: !doSiteListingData,
    onCompleted: (data) => {
      if (data.getDOCustomer) {
        // set form values
        setValues({
          ...values,
          ...data.getDOCustomer.contactName?.[0],
          ...data.getDOCustomer.contactInfo?.[0],
          birthdate: data.getDOCustomer.birthdate
            ? data.getDOCustomer.birthdate
            : '',
          country: data.getDOCustomer.contactInfo?.[0].country
            ? data.getDOCustomer.contactInfo?.[0].country
            : 'FR',
          email: data.getDOCustomer.login,
          language: data.getDOCustomer.siteDomain?.id
            ? data.getDOCustomer.siteDomain?.id
            : doSiteListingData?.getDOSiteListing.edges.find(
                (DOSite: any) => DOSite.node.domainSuffix === 'fr'
              ).node.id,
          password:''
        })
        setBlackListed(!!data.getDOCustomer.blacklisted)
      }
    },
  })

  // state of toggle blacklist
  const [blacklisted, setBlackListed] = useState(false)
  const [updateCustomer, { loading: mutationLoading, error: mutationError }] =
    useMutation(UPDATE_DO_CUSTOMER, {
      onCompleted: (data) => {
        if(data){
          const customerData = data.updateCustomer.output.customer
          // if updated values are not valid, update the values from the backend and clear password input 
          setValues({
            ...values,
            ...customerData.contactName?.[0],
            ...customerData.contactInfo?.[0],
            birthdate: customerData.birthdate
              ? customerData.birthdate
              : '',
            email: customerData.login,
            password:''
          })
        }
        if (!mutationError){
          // if the mutation is done without error : send Toast
          setIsFocusOut({})
          toast({
            title: 'Modifications enregistrées pour :',
            message: `Client id ${id}`,
          })
        }
      },
      refetchQueries: [
        {
          query: GET_ORDER_INFO,
          variables: { id: +id }
        }
      ]
    })

  //fonction qui fera la mutation d'updateCustomer si tous les champs sont valides
  const updateCustomerPromise = async () => {
    try {
      await updateCustomer({
        variables: {
          ...values,
          id: +id,
          civility: values.civility.toString(),
          siteDomainId: +values.language,
          blacklisted,
        },
      })
    } catch (e) {
      // NB : gestion erreur dans App.tsx
    }
  }
  const blacklistCustomerSwitchHandler = () => {
    //change le toggle
    setBlackListed(!blacklisted)
    //ajoute une coche si on touche au toggle et vérifie s'il y a des erreurs dans le formulaire
    isFocusOutAndSetErrors('blacklisted')
  }

  if (customerLoading || countriesListingLoading || doSiteListingLoading) {
    return (
      <div className="p-3">
        <h2>
          <Skeleton />
        </h2>
        <SkeletonBase titleCount={11} count={6} />
        <h2>
          <Skeleton />
        </h2>
      </div>
    )
  } else if (!customerData || !countriesListingData || !doSiteListingData) {
    return (
      <Card>
        <p>Aucun client trouvé</p>
      </Card>
    )
  }
  if (customerError) return <p>`Error! ${customerError.message}`</p>
  const inputsTab = [
    {
      inputType: 'radio',
      inputName: 'civility',
      isRequired: true,
      radioOptions: ['madame', 'monsieur'],
    },
    { inputType: 'text', inputName: 'firstName', isRequired: true },
    { inputType: 'text', inputName: 'lastName', isRequired: true },
    { inputType: 'date', inputName: 'birthdate', isRequired: true },
    { inputType: 'select', inputName: 'country', isRequired: true },
    { inputType: 'text', inputName: 'email', isRequired: true },
    { inputType: 'password', inputName: 'password', isRequired: false },
    { inputType: 'select', inputName: 'language', isRequired: true },
    { inputType: 'text', inputName: 'landlinePhoneNumber', isRequired: false },
    { inputType: 'text', inputName: 'mobilePhoneNumber', isRequired: false },
    { inputType: 'text', inputName: 'postalCode', isRequired: true },
    { inputType: 'toggle', inputName: 'blacklisted' },
  ]

  //check if errors key is valid (is in inputsTab)
  const hasValidKey = (key:string, inputsTab:any) => {
    const inputNames = inputsTab.map((input:any) => input.inputName);
    return inputNames.includes(key)
  }
  //check if form has error
  const hasError = (errors: any) => {
  for (const key in errors) {
    if (hasValidKey(key, inputsTab) && errors.hasOwnProperty(key) && ((errors[key] !== undefined && key!=='postalCode') 
    || errors[key]==='Veuillez renseigner ce champ')) {
      return true //error found
    }
  }
  return false //error not found
}
  const hasOrdersForDomains = (orders: orderType[], domains: string[]): { [key: string]: boolean } => {
      return domains.reduce((acc, domain) => {
          acc[domain] = orders.some(order => order.siteDomain.domainSuffix === domain);
          return acc;
      }, {} as { [key: string]: boolean });
  }
    const domainsToCheck = ["fr", "it", "es"]
    const domainOrders = hasOrdersForDomains(customerData.getDOCustomer.orders, domainsToCheck)

    const domainConfig = [
        { key: "fr", url: REACT_APP_PIMCORE_URL_DOMAIN },
        { key: "it", url: REACT_APP_PIMCORE_URL_DOMAIN_it },
        { key: "es", url: REACT_APP_PIMCORE_URL_DOMAIN_es },
    ]

  return (
    <>
      <div className="EditCustomer">
        {domainConfig.map(({ key, url }) => (
          (domainOrders[key] || customerData.getDOCustomer.siteDomain?.domainSuffix === key) && id && url && (
              <CustomerDirectConnect key={key} pimcoreDomainUrl={url} id={id} locale={key} />
          )
        ))}
        {/* <Button className="m-2">Générer un nouveau mot de passe</Button> */}
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            handleSubmit(e, updateCustomerPromise)
          }}
          className="p-2"
        >
          {inputsTab.map((input) => {
            if (input.inputName === 'blacklisted') {
              return (
                <Form.Group className="d-flex  m-2" key={input.inputName}>
                  <Form.Label className="formLabelWidth me-3">
                    Client Blacklisté
                  </Form.Label>

                  <div
                    className={`d-flex ${blacklisted} ? 'text-danger' : 'text-secondary'`}
                  >
                    <Form.Check
                      type="switch"
                      id="blacklisted"
                      checked={blacklisted}
                      onChange={blacklistCustomerSwitchHandler}
                    />
                    <span
                      className={`m-0 ${blacklisted ? 'text-danger' : ' '}`}
                    >
                      {blacklisted ? 'OUI' : 'NON'}
                    </span>{' '}
                    {isFocusOut.blacklisted && (
                      <CheckIcon className="header-title-icon text-success ms-3" />
                    )}
                  </div>
                </Form.Group>
              )
            } else if (input.inputType === 'select') {
              return (
                <InputCustom
                  key={input.inputName}
                  values={values}
                  errors={errors}
                  inputName={input.inputName}
                  inputType={input.inputType}
                  handleChangeSelect={handleChangeSelect}
                  handleFocusOutSelect={handleFocusOutSelect}
                  isFocusOut={isFocusOut}
                  required={input.isRequired}
                  children={
                    input.inputName === 'country'
                      ? countriesListingData.getCountriesListing.edges.map(
                          (country: any) => {
                            //options for country
                            return (
                              <option
                                key={country.node.key}
                                value={country.node.value}
                              >
                                {country.node.key}
                              </option>
                            )
                          }
                        )
                      : doSiteListingData.getDOSiteListing.edges.map(
                          (doSite: any) => {
                            //options for language
                            return (
                              <option
                                key={doSite.node.id}
                                value={doSite.node.id}
                              >
                                {t(doSite.node.domainSuffix)}
                              </option>
                            )
                          }
                        )
                  }
                />
              )
            } else if (input.inputType === 'radio') {
              return (
                <InputCustom
                  key={input.inputName}
                  values={values}
                  errors={errors}
                  inputName={input.inputName}
                  inputType={input.inputType}
                  handleChange={handleChange}
                  isFocusOut={isFocusOut}
                  required={input.isRequired}
                  radioOptions={input.radioOptions}
                  isFocusOutAndSetErrors={isFocusOutAndSetErrors}
                />
              )
            }
            return (
              <div className='position-relative' key={input.inputName}>
                <InputCustom
                  key={input.inputName}
                  values={values}
                  errors={errors}
                  inputName={input.inputName}
                  inputType={input.inputType}
                  handleChange={handleChange}
                  handleFocusOut={handleFocusOut}
                  isFocusOut={isFocusOut}
                  required={input.isRequired}
                  handleGeneratePassword={handleGeneratePassword}
                  handleCopyPassword={handleCopyPassword}
                />
              { !values['landlinePhoneNumber'] && !values['mobilePhoneNumber'] && input.inputName==='mobilePhoneNumber' && <span className='small-content text-danger phone-error'>Il faut au moins un numéro de téléphone.</span>}
              </div>
            )
          })}

          <div className='ms-2'>
            Date d'inscription :{' '}
            {new Intl.DateTimeFormat('fr', {
              dateStyle: 'short',
              timeStyle: 'medium',
            }).format(new Date(customerData.getDOCustomer.creationDate * 1000))}
          </div>

          <div className='ms-2'>
            Date de dernière commande :{' '}
            {customerData.getDOCustomer.lastOrderDate}
          </div>

          <div className="my-3 d-flex align-items-center">
            {' '}
            <Button type="submit" disabled={mutationLoading|| hasError(errors)|| (!values['landlinePhoneNumber'] && !values['mobilePhoneNumber'])}>
              {mutationLoading ? (
                <>
                  {' '}
                  Enregistrement en cours{' '}
                  <Spinner
                    variant="light ms-2"
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />{' '}
                </>
              ) : (
                <>Enregistrer les modifications</>
              )}
            </Button>
          </div>
        </Form>
      </div>

      <ToggleCustomerVouchers customerId={id} />

      <Accordion>
        {/* toggle orders */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Commandes</Accordion.Header>
          <Accordion.Body>
            <div className="m-2">
              <OrderHistoryTable customer={customerData.getDOCustomer} />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}

export default EditCustomer
