import { gql } from '@apollo/client'
import { DO_VOUCHER_FIELDS } from 'graphql/fragments/DOVoucherFields'

const APPLY_VOUCHER_IN_STORE = gql`
    ${DO_VOUCHER_FIELDS}
    mutation APPLY_VOUCHER_IN_STORE(
      $id: Int!
      $usageDate: String!
      $storeId: Int!
      $cosiumQuoteStore : String!
      $withoutNewVoucherCode: Boolean = true
      $voucherState: String  # "disponible" |"utilise" | "annule" | "desactive"
      $isStoreUsed: Boolean 
      $index: Int # index of the voucher usage in the voucherUsage array
    ) {
      updateDOVoucher(
        id: $id
        input: {
          voucherState: $voucherState
          isStoreUsed: $isStoreUsed
          voucherUsage: {
            replace: false
            items: {
              DOVoucherUsageFC: [
                {
                  index: $index
                  usageDate: $usageDate
                  store: { id: $storeId, type: "object" }
                  cosiumQuoteStore: $cosiumQuoteStore
                }
              ]
            }
          }
        }
      ) {
        success
        output {
          ...DOVoucherFields
        }
      }
    }
  `

export default APPLY_VOUCHER_IN_STORE