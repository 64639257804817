import { useParams } from 'react-router-dom'
import Card from '../../components/UI/Card'
import VoucherDetails from './VoucherDetails'
import { useMutation, useQuery } from '@apollo/client'
import GET_DO_VOUCHER from 'graphql/queries/getDOVoucher'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import { useContext, useState } from 'react'
import AuthContext from 'store/auth-context'
import APPLY_VOUCHER_IN_STORE from 'graphql/mutations/applyVoucherInStore'

const GestionBDR = () => {
  const { id } = useParams<ParamType>()
  const authCtx = useContext(AuthContext)

  const {
    loading: voucherLoading,
    data: voucherData,
    error: voucherError,
  } = useQuery(GET_DO_VOUCHER, {
    variables: {
      id: +id,
    },
  })

  const [cosiumQuoteStore, setCosiumQuoteStore] = useState('');

  const [applyVoucherInStore] = useMutation(APPLY_VOUCHER_IN_STORE
, {
    variables: {
      id: +id,
      // if voucher is generic, it is always available
      voucherState: voucherData?.getDOVoucher?.isGeneric  ? 'disponible' : 'utilise', 
      isStoreUsed: voucherData?.getDOVoucher?.isGeneric ? false : true, 
      usageDate: `${new Date().toISOString()}`,
      storeId: parseInt(authCtx.user.storeId),
      cosiumQuoteStore: cosiumQuoteStore,
      index: voucherData?.getDOVoucher.voucherUsage?.length ?? 0
    },
  })

  if (id == null) {
    return (
      <Card>
        <h1>Bon de réduction</h1>
        <p>Aucun bon de réduction recherché</p>
      </Card>
    )
  }

  if (voucherLoading) {
    return (
      <Card>
        <h1>Bon de réduction</h1>
        <SkeletonBase count={6} />
      </Card>
    )
  }

  if (voucherError && !voucherError.message.match(/object with(.*)not found/))
    return <p>`Error! ${voucherError.message}`</p>

  if (!voucherData)
    return (
      <Card>
        <h1>Bon de réduction</h1>
        <p>Aucun bon de réduction trouvé</p>
      </Card>
    )

  return (
    <Card>
      <h1>Bon de réduction {voucherData.getDOVoucher.voucherCode}</h1>
      <VoucherDetails
        voucher={voucherData.getDOVoucher}
        mutationFunction={applyVoucherInStore}
        btnLabel="Utiliser le bon"
        confirmMessage="Souhaitez-vous utiliser le bon :"
        toastTitle="Utilisation du bon :"
        onInputValueChange={(value) => {
          setCosiumQuoteStore(value)
        }}
      />
    </Card>
  )
}

export default GestionBDR
