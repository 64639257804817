import { ClipboardListIcon } from '@heroicons/react/outline'
import HeaderTitle from '../HeaderTitle'
import { Table } from 'react-bootstrap'
import { constants } from '../../utils/constants'
import { useState } from 'react'
interface Props {
  customer: customerType
  orderId?: string
}
const OrderHistoryTable: React.FC<Props> = ({ customer, orderId }) => {
  const [isAscending, setIsAscending] = useState(false) // default sort orders by descending id 
  const toggleSortOrder = () => {
    setIsAscending((prev) => !prev)
  }
  const getClassTextColor = (workflowState: string) => {
    if (
      workflowState === constants.workflow.state.CREATED ||
      workflowState === constants.workflow.state.AWAITING_PAYMENT
    ) {
      // si le workflowState n'est pas created ou n'est pas awaiting_payment la commande n'est pas payé, le texte sera rouge
      return 'text-danger'
    }
    // si la commande est payé le texte sera vert
    return 'text-success'
  }

  return customer.orders?.length || customer.ordersOldSite?.length ? (
    <div>
      <div>
        <HeaderTitle
          title="Historique commandes de ce client"
          children={<ClipboardListIcon className="header-title-icon" />}
        />

        <Table bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Type de la cde</th>
               <th onClick={toggleSortOrder} className='cursor'>
                Date de création {isAscending ? '▲' : '▼'}
              </th>
              <th>N° de Commande</th>
              <th>Bénéficiaire</th>
              <th>Modification</th>
              <th className="col-md-4">Etat</th>
            </tr>
          </thead>
          <tbody>
            {/* list of orders for this customer */}
            {customer.orders
              ?.slice(0)
              .sort((a: orderType, b: orderType) =>
                isAscending ? Number(a.id) - Number(b.id) : Number(b.id) - Number(a.id)
              )
              .map((customerOrder: orderType, index) => {
                // list of beneficiaries
                let beneficiaries = [] as string[]
                //map on orderlines then on prescription to find every beneficiaries
                customerOrder?.orderLines?.map((orderLine: any) => {
                  return orderLine.prescription?.beneficiary.map(
                    (beneficiary: any) => {
                      return beneficiaries.push(
                        beneficiary.name[0].firstName +
                          ' ' +
                          beneficiary.name[0].lastName
                      )
                    }
                  )
                })

                return (
                  <tr
                    key={index}
                    className="cursor"
                    onClick={() => {
                      //open the order on new window
                      window.open(
                        `/bo/relation_client/gestion_commande_client/${customerOrder.id}`,
                        '_blank'
                      )
                    }}
                  >
                    <th scope="row">{index + 1}</th>
                    <td
                      className={getClassTextColor(customerOrder.workflowState)}
                    >
                      {customerOrder.orderType}
                    </td>
                    <td
                      className={getClassTextColor(customerOrder.workflowState)}
                    >
                      {new Intl.DateTimeFormat('fr', {
                        dateStyle: 'short',
                        timeStyle: 'medium',
                      }).format(customerOrder.creationDate * 1000)}
                    </td>
                    <td
                      className={getClassTextColor(customerOrder.workflowState)}
                    >
                      {customerOrder.key}
                      {orderId && customerOrder.id === orderId ? (
                        <span
                          className={`fw-bold ${getClassTextColor(
                            customerOrder.workflowState
                          )}`}
                        >
                          {' '}
                          (actuelle)
                        </span>
                      ) : (
                        ''
                      )}
                    </td>
                    <td
                      className={getClassTextColor(customerOrder.workflowState)}
                    >
                      {!!beneficiaries.length ? beneficiaries[0] : ''}
                    </td>
                    <td
                      className={getClassTextColor(customerOrder.workflowState)}
                    >
                      {new Intl.DateTimeFormat('fr', {
                        dateStyle: 'short',
                        timeStyle: 'medium',
                      }).format(customerOrder.modificationDate * 1000)}
                    </td>
                    <td className="col-md-3 text-center">
                      <span
                        className="fw-bold p-1 text-dark"
                        style={{
                          backgroundColor: `${
                            constants.workflow.labelColor[
                              customerOrder.workflowState
                            ].color
                          }`,
                        }}
                      >
                        {
                          constants.workflow.labelColor[
                            customerOrder.workflowState
                          ].label
                        }
                      </span>
                    </td>
                  </tr>
                )
              })}
            {/* list of ordersOldSite for this customer */}
            {customer.ordersOldSite
              ?.slice(0)
              .reverse()
              .map((orderOldSite: any, index) => {
                return (
                  <tr key={orderOldSite.orderCode}>
                    <th>{customer.orders.length + index + 1}</th>
                    <td>{orderOldSite.orderType}</td>
                    <td>
                      {new Intl.DateTimeFormat('fr', {
                        dateStyle: 'short',
                        timeStyle: 'medium',
                      }).format(
                        new Date(orderOldSite.creationDateOrderOldSite)
                      )}
                    </td>
                    <td>{orderOldSite.orderCode}</td>
                    <td>{orderOldSite.beneficiaries}</td>
                    <td></td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
      <span className="text-success">cde payée</span>
      {' - '}
      <span className="text-danger">cde non payée</span>
      {' - '}
      <span>cde ancien site</span>
    </div>
  ) : (
    <p>Aucune commande trouvée</p>
  )
}

export default OrderHistoryTable
