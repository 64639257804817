import './OrderDetailCard.css'
import { DuplicateIcon } from '@heroicons/react/outline'
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  getEyewearData,
  sortOrderLinesByPrescriptions,
  sortOrderLinesByType,
} from 'utils/functions'
import Prescription from './Prescription'
import OrderDetailLeftSide from './OrderDetailLeftSide'
import TypeBadge from './TypeBadge'
import { useTranslation } from 'react-i18next'
import VisionTypeForm from './VisionTypeForm'
import DUPLICATE_ORDER_LINE from 'graphql/mutations/duplicateOrderLine'
import { useMutation } from '@apollo/client'
import { Form, Button, Modal, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import DOModal from 'components/Modal/DOModal'
import EditFrameModal from './EditFrameModal'
import EditOrderLineItemModal from './EditOrderLineItemModal'
import EditAccessoryModal from './EditAccessoryModal'
import GlobalContext from 'store/global-context'
import CM_UPDATE_ORDER_LINE_ITEM from 'graphql/mutations/updateDOOrderLineItem'
import AuthContext from 'store/auth-context'
import CopyableCell from '../../utils/CopyableCell'
// import GET_ORDER_ACCOUNTING_EVENTS_DATA from 'graphql/queries/getDOOrderAccountingEventsData'
// import CM_CREATE_DO_ORDER_EVENT from 'graphql/mutations/CreateDOOrderEvent'
// import BULK_CLOSE_ORDER_EVENTS from 'graphql/mutations/bulkCloseOrderEvents'
// import { constants } from 'utils/constants'
interface Props {
  order: orderType
  onChangeCheckedIdsList: (orderLinesIds: string[]) => void
  initCheckedIdList: string[]
  calculators: orderCalculatorsType
}

const OrderDetailTable: React.FC<Props> = ({
  order,
  calculators,
  onChangeCheckedIdsList,
  initCheckedIdList,
}) => {
  const globalContext = useContext(GlobalContext)
  const authCtx = useContext(AuthContext)

  const [openPrescription, setOpenPrescription] = useState<null | number>(null)
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
  const [checkedIdList, setCheckedIdList] = useState<string[]>([])
  const [showDupModal, setShowDupModal] = useState<boolean>(false)
  const [duplicatedLine, setDuplicatedLine] = useState<any>(null)
  const [editionModal, setEditionModal] = useState('')
  // state to set item and orderLine used in modals
  const [itemAndOrderLine, setItemAndOrderLine] = useState({} as any)
  const [editingOrderLineId, setEditingOrderLineId] = useState<number | null>(null);
  const [orderLineValue, setOrderLineValue] = useState<number | undefined>(undefined);
  const divInputRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const isBeneficiaryOff =
    order.orderLines.filter(
      (orderLine) => orderLine.prescription && orderLine.status === 'on'
    ).length === 0
  const [aftersalesKeepFrame, setAftersalesKeepFrame] = useState<boolean>(false)
  const [aftersalesKeepLenses, setAftersalesKeepLenses] = useState<boolean>(false)

  const handleCloseDupModal = () => {
    setShowDupModal(false)
    setAftersalesKeepLenses(false)
    setAftersalesKeepFrame(false)
  }
  const handleShowDupModal = () => setShowDupModal(true)

  const { t } = useTranslation()
  const { toast } = useContext(ToastContext)

  const [duplicateOrderLine, { loading }] = useMutation(DUPLICATE_ORDER_LINE)
  const [updateOrderLineItem, { loading: updateLoading}] = useMutation(CM_UPDATE_ORDER_LINE_ITEM);
  // const [closeOrderEvents, { loading: closeOrderEventsLoading}] = useMutation(BULK_CLOSE_ORDER_EVENTS);
  // const { loading: accountingLoading, data } = useQuery(GET_ORDER_ACCOUNTING_EVENTS_DATA, {
  //   variables: {
  //     id: parseInt(order.id),
  //   },
  // })

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckAll((prevIsCheckAll) => !prevIsCheckAll)

    const idList = [] as any
    order.orderLines.map((orderLine: orderLineType) => {
      return orderLine.state !== 'fac' ? idList.push(orderLine.id) : ''
    })

    setCheckedIdList(e.target.checked ? idList : [])

    onChangeCheckedIdsList(e.target.checked ? idList : [])
  }

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target
    if (checked) {
      setCheckedIdList((prevIdList) => [...prevIdList, id])
    } else {
      setCheckedIdList(checkedIdList.filter((item: string) => item !== id))
    }

    onChangeCheckedIdsList([id])
  }

  const handleDuplicate = async (
    duplicatedLine: any,
  ) => {
    try {
        const {
          items,
          prescription,
          state,
        } = duplicatedLine;

        let prescriptionId = +prescription?.id??null;
        let frameId = null;
        let frameQuantity = null;
        let frameAmount = null;
        let lensReplacementId = null;
        let accessoryId = null;
        let accessoryQuantity = null;
        let accessoryAmount = null;
        let beneficiaryId = +prescription?.beneficiary[0]?.id??null;
        const eyewearData = getEyewearData(items);
        let jsonLenses = '';
        let genericItem = null;
        let shippingFeeId = null;
        let voucherId = null;
        let key = null;
        let label = null;
        let quantity = null;
        let amount = null;

        items.forEach((item:any) => {
          switch (item.item[0].__typename) {
            case 'fieldcollection_DOOLItemFrameFC':
              frameId = +item.item[0]?.frame[0]?.id
              frameQuantity = +item.quantity
              frameAmount = +item?.unitAmount.value
              break
            case 'fieldcollection_DOOLItemLensReplacementFC':
              lensReplacementId = +item.item[0]?.lensReplacement[0]?.id
              frameQuantity = +item.quantity
              frameAmount = +item.unitAmount.value
              break
            case 'fieldcollection_DOOLItemAccessoryFC':
              accessoryId = +item.item[0]?.accessory[0]?.id
              accessoryQuantity = +item.quantity
              accessoryAmount = +item?.unitAmount.value
              break
            case 'fieldcollection_DOOLItemGenericFC':
              genericItem = item.item[0]?.genericItem[0]
              key = genericItem.key
              label = item.label
              quantity = +item.quantity
              amount = +item?.unitAmount.value
              break;
            case 'fieldcollection_DOOLItemLensFC':
              jsonLenses = JSON.stringify({right:eyewearData.right,left:eyewearData.left});
              break
            case 'fieldcollection_DOOLItemShippingFC':
              shippingFeeId = +item.id
              break;
            case 'fieldcollection_DOOLItemVoucherFC':
              voucherId = +item.id
            break;
          }
        })
        await duplicateOrderLine({
          variables: { 
            orderId:+order.id,
            orderLineId: +duplicatedLine.id, 
            state,
            frameId,
            frameQuantity,
            frameAmount,
            lensReplacementId,
            prescriptionId,
            beneficiaryId,
            jsonLenses,
            key,
            label,
            quantity,
            amount,
            newPrescription: false, // Prescription is always copied
            shippingFeeId,
            voucherId,
            accessoryId,
            accessoryQuantity,  
            accessoryAmount,
            aftersalesKeepLenses,
            aftersalesKeepFrame
          },
        })
        toast({
          title: 'Modifications enregistrées :',
          message: 'Ligne dupliquée !',
        })
    } catch (e) {} finally {
      handleCloseDupModal()
    }
  }

  const getCosiumAmount = () => {
    return order.totalAmountHistory?.reduce((prev: any, current: any) =>
    +prev.entryDate.replace(/\D/g, '') > +current.entryDate.replace(/\D/g, '')
      ? prev
      : current
    )?.totalAmount.value ?? 0
  }

  const getOrderLineItemsAmount = (orderLine: orderLineType) => {
    return orderLine.items
      .map((item: itemType) => (item?.item?.find((innerItem: any) => innerItem.hasOwnProperty('voucher') || (innerItem.hasOwnProperty('genericItem') && innerItem.genericItem[0].isNegative)) ? -item.unitAmount.value : item.unitAmount.value) * item.quantity)
      .reduce((acc, currentValue) => acc + currentValue)
  }

  const getOrderLineTotalAmount = () =>
  {
    let totalOrderLines = order.orderLines
    .map((orderLine: orderLineType) => !calculators.isPaid || orderLine.status === 'on' ? getOrderLineItemsAmount(orderLine) : 0)
    .reduce((acc, currentValue) => acc + currentValue)
    return totalOrderLines < 0 ? 0 : totalOrderLines
  }

  // const [createDOOrderEvent, { loading: loadingOrderEvent }] = useMutation(
  //   CM_CREATE_DO_ORDER_EVENT,
  //   {
  //     onCompleted: (data) => {
  //       toast({
  //         title: 'Succès :',
  //         message: 'Statut du montant mis à jour.'
  //       })
  //     },
  //   }
  // )

  const handlePreUpdate = (item: any) => {
    const inputElement = divInputRefs.current[item.id]?.querySelector('input');
    if (inputElement && inputElement.value !== '') {
      let newValue = inputElement.value
      if(newValue) {
        if (newValue.includes(',')) {
          newValue = newValue.replace(',', '.')
        }

        if (newValue.startsWith('.') && newValue.length > 1) {
          newValue = '0' + newValue
        }

        let parsedValue = parseFloat(newValue);
        if (isNaN(parsedValue) || newValue === '') {
          handleUpdate(null);
          inputElement.value = ''
        }else{
          parsedValue = Math.round(parsedValue * 100) / 100;
          handleUpdate(parsedValue);
        }
      }
    } else {
      handleUpdate(null);
      if(inputElement) {
        inputElement.value = ''
      }
    }
  }

  const duplicatedLineIsLensReplacement = () => {
    return duplicatedLine && duplicatedLine.items?.find((item: any) => {
      return item.item?.[0].__typename === 'fieldcollection_DOOLItemLensReplacementFC'
    }) !== undefined
  }
 const duplicatedLineHasLens = () => {
    return duplicatedLine && duplicatedLine.items?.find((item: any) => {
      return item.item?.[0].__typename === 'fieldcollection_DOOLItemLensFC'
    }) !== undefined
  }

  const handleUpdate = async (unitAmount: number | null) => {
    if (unitAmount === null) {
      setOrderLineValue(undefined);
      setEditingOrderLineId(null);
      return;
    }
    if(editingOrderLineId !== null) {
      const inputElement = divInputRefs.current[editingOrderLineId]?.querySelector('input')
      const oldAmount = inputElement ? parseFloat(inputElement.placeholder) : null
      if(oldAmount !== unitAmount) {
        await updateOrderLineItem({
          variables: {
            orderLineItemId: +editingOrderLineId,
            unitAmount,
            oldAmount
          }
        })
        // if(oldAmount !== null) {
        //   let amount = 0
        //   if(oldAmount > unitAmount) {
        //     amount = -(oldAmount - unitAmount)
        //   } else {
        //     amount = unitAmount - oldAmount
        //   }
        //   const totalPaiement = Number(data.CQgetDOOrderAccountingEventsData.totalPaymentAmount.toFixed(2))
        //   const totalAPayer = Number((Number((getOrderLineTotalAmount() - getCosiumAmount())?.toFixed(2)) + amount).toFixed(2))
        //   if(totalPaiement !== totalAPayer) {
        //     if(totalPaiement > totalAPayer){
        //       await createDOOrderEvent({
        //         refetchQueries: [GET_ORDER_ACCOUNTING_EVENTS_DATA],
        //         awaitRefetchQueries: true,
        //         variables: {
        //           mailInfo: parseFloat((totalPaiement - totalAPayer).toString()).toFixed(2),
        //           orderId: parseInt(order.id),
        //           sentEmail: false,
        //           eventTypeKey: constants.eventTypeKeys.ORDER_EVENT_TYPE_TO_REFUND
        //         },
        //       })
        //     }else{
        //       await createDOOrderEvent({
        //         refetchQueries: [GET_ORDER_ACCOUNTING_EVENTS_DATA],
        //         awaitRefetchQueries: true,
        //         variables: {
        //           mailInfo: parseFloat((totalAPayer - totalPaiement).toString()).toFixed(2),
        //           orderId: parseInt(order.id),
        //           sentEmail: true,
        //           eventTypeKey: constants.eventTypeKeys.ORDER_EVENT_TYPE_CUSTOMER_MUST_PAY_EXTRA
        //         },
        //       })
        //     }
        //   }else{
        //     const lastEvent = data.CQgetDOOrderAccountingEventsData.edges[data.CQgetDOOrderAccountingEventsData.edges.length - 1].node
        //     if(!lastEvent.closureDate) {
        //       await closeOrderEvents({
        //         refetchQueries: [GET_ORDER_ACCOUNTING_EVENTS_DATA],
        //         awaitRefetchQueries: true,
        //         variables: {
        //           orderId: parseInt(order.id),
        //           eventsId: [parseInt(lastEvent.id)]
        //         }
        //       })
        //     }
        //   }
        //}
      }
      setOrderLineValue(undefined);
      setEditingOrderLineId(null);
    }
  };

  useEffect(() => {
    setCheckedIdList(initCheckedIdList)
    let numberOfOrderLinesNotFac = 0
    order.orderLines.map((orderLine: orderLineType) => {
      return orderLine.state !== 'fac' ? numberOfOrderLinesNotFac++ : ''
    })
    setIsCheckAll(checkedIdList.length === numberOfOrderLinesNotFac)
  }, [setCheckedIdList, initCheckedIdList, checkedIdList, order])

  useEffect(() => {
    globalContext.setOrderKey(order.key)
    globalContext.setSiteDomainSuffix(order.siteDomain.domainSuffix)
    globalContext.setCosiumId(calculators.cosiumId)
    globalContext.setIsAftersalesOrder(order.orderLines.find((orderLine: orderLineType) => {
      return orderLine.status === 'on' && orderLine.aftersales === true
    }) !== undefined)
  }, [order, calculators, globalContext])

  useEffect(() => {
    const handleClickOrderlineOutside = (event: MouseEvent) => {
      if (editingOrderLineId !== null && divInputRefs.current[editingOrderLineId.toString()] && !divInputRefs.current[editingOrderLineId.toString()]?.contains(event.target as Node)) {
        setEditingOrderLineId(null)
        setOrderLineValue(undefined)
      }
    };
  
    document.addEventListener('mousedown', handleClickOrderlineOutside);
  
    return () => {
      document.removeEventListener('mousedown', handleClickOrderlineOutside);
    };
  }, [editingOrderLineId]);

  const orderLinesWithPrescription = sortOrderLinesByPrescriptions(
    order.orderLines
  )
  //on construit le nouveau tableau d'orderLines pour les afficher dans le bon ordre
  const newOrderLines = [
    ...orderLinesWithPrescription,
    ...sortOrderLinesByType(order.orderLines),
  ]

  // Duplication is only dependent on order type and login permissions from now on
  const canBeDuplicated = (authCtx?.user?.isAdmin || 
    (authCtx?.user?.roles as string[]).includes('logistique_nantes')) &&
    order.orderType === 'panier'
  const shadingLabelHandler = (label: string) => {
    const splittedString = label?.split('Dégradé')
    if (splittedString?.length >= 2) {
      return (
      <>
        {splittedString[0]}
        <u><strong>Dégradé</strong></u>
        {splittedString.slice(1).join('Dégradé')}
      </>
      )
    }
    return label
  }

  const copyableEanLabel = (label: string) => {
    if(/gtin/.test(label))
    {
      const splittedLabel = label.split('gtin')
      let gtin = splittedLabel[1].replace('(','').replace(')','').trim()
      return <>{splittedLabel[0]} (<CopyableCell noTd={true} noSpace={true}>{gtin}</CopyableCell>)</>
    }
    return label
  }

  const table = newOrderLines.map((groupOfOrderLines: any, i: number) => {
    return (
      <tr key={i}>
        {/* empty td for prescription col */}
        <td></td>
        <td>
          <table className="w-100 p-2">
            <tbody>
              {groupOfOrderLines?.map((orderLine: orderLineType, k: number) => {
                const isGroupOff =
                  groupOfOrderLines.filter(
                    (orderLine: orderLineType) =>
                      orderLine.prescription && orderLine.status === 'on'
                  ).length === 0
                  const isOrderLineDuplicated = orderLine.status === 'on' && /duplicated|copy/i.test(orderLine.key)

                // Check si la ligne est désactivée
                let desactiveItemTextColor = ''

                if (orderLine.status === 'off') {
                  desactiveItemTextColor = 'text-lightgray'
                }

                return (
                  <tr className="hover-green" key={orderLine.id}>
                    {k === 0 &&
                      order.orderType !== 'essdom' &&
                      orderLine.prescription && (
                        <td
                          rowSpan={groupOfOrderLines.length}
                          className="bg-green align-top w-0"
                        >
                          <OrderDetailLeftSide
                            beneficiary={
                              groupOfOrderLines[0].prescription.beneficiary[0]
                            }
                            calculators={calculators}
                            groupOfOrderLines={groupOfOrderLines}
                            prescription={groupOfOrderLines[0].prescription}
                            openPrescription={openPrescription}
                            setOpenPrescription={setOpenPrescription}
                            numberOfPrescription={i + 1}
                            isGroupOff={isGroupOff}
                            isBeneficiaryOff={isBeneficiaryOff}
                          />
                        </td>
                      )}
                    {!orderLine.prescription &&
                      order.orderType !== 'essdom' && (
                        <td
                          className="bg-lightgray"
                          style={{ width: '290px' }}
                        ></td>
                      )}

                    <td className={isOrderLineDuplicated?'bg-duplication hover-green':''}>
                      {orderLine.items.map((item: any, j: number) => {
                        const getNumberOfEquipement = () => {
                          let numberOfEquipement
                          orderLinesWithPrescription.map(
                            (groupByPrescription: any) => {
                              if (
                                groupByPrescription[0].prescription.id ===
                                orderLine?.prescription.id
                              ) {
                                return (numberOfEquipement =
                                  groupByPrescription.length)
                              }
                              return ''
                            }
                          )
                          return numberOfEquipement
                        }
                        let gtin = item?.item?.[0]?.frame?.[0]?.gtin ?? item?.item?.[0]?.accessory?.[0]?.gtin
                        let orderDetailLabel = item?.label + gtin ? ` (${gtin})` : ''
                        // check if item and item[0] exist (item.item is null for Service Essdom)
                        if(item?.item?.[0]?.__typename)
                          switch (item.item[0].__typename) {
                            case 'fieldcollection_DOOLItemFrameFC':
                            case 'fieldcollection_DOOLItemAccessoryFC':
                              orderDetailLabel = item?.label + " gtin("+ gtin + ")"
                            break
                            case 'fieldcollection_DOOLItemVoucherFC':
                              orderDetailLabel =  `${item.item[0].voucher[0]?.voucherCode} (${item.label})`
                            break
                            default:
                              orderDetailLabel = item?.label
                        }
                        else
                          orderDetailLabel = item?.label
                        return (
                          <table className="w-100" key={item.id}>
                            <tbody>
                              <tr>
                                <td className="w-50 px-2">
                                  <TypeBadge
                                    item={item}
                                    orderLine={orderLine}
                                    setEditionModal={setEditionModal}
                                    setItemAndOrderLine={setItemAndOrderLine}
                                  />
                                  <span
                                    className={`${desactiveItemTextColor} text-break`}
                                  >
                                    {' '}{item.item && item.item[0].__typename === "fieldcollection_DOOLItemLensFC" ? 
                                      shadingLabelHandler(orderDetailLabel) : copyableEanLabel(orderDetailLabel)}
                                  </span>
                                  {orderLine.prescription &&
                                    item.item &&
                                    [
                                      'fieldcollection_DOOLItemFrameFC',
                                      'fieldcollection_DOOLItemLensReplacementFC',
                                    ].includes(item.item[0].__typename) && (
                                      <>
                                        <div className={desactiveItemTextColor}>
                                          {orderLine.visionType ===
                                            'non_renseigne' ||
                                          orderLine.visionType === ''
                                            ? "Cette monture n'a pas de type vision défini"
                                            : `Cette monture est pour ${t(
                                                orderLine.visionType
                                              )}`}
                                        </div>
                                        {/* set vision type */}
                                        {orderLine.status === 'on' && (
                                          <VisionTypeForm
                                            orderLine={orderLine}
                                          />
                                        )}
                                        {orderLine?.prescription
                                          ?.customerComment && (
                                          <div
                                            className={`${
                                              desactiveItemTextColor
                                                ? desactiveItemTextColor
                                                : 'text-danger'
                                            } text-break`}
                                          >
                                            <strong
                                              className={`${
                                                desactiveItemTextColor
                                                  ? desactiveItemTextColor
                                                  : 'text-danger'
                                              }`}
                                            >
                                              Commentaire client :
                                            </strong>{' '}
                                            {
                                              orderLine.prescription
                                                .customerComment
                                            }
                                          </div>
                                        )}
                                        {orderLine?.prescription
                                          ?.internalComment && (
                                          <div
                                            className={`${
                                              desactiveItemTextColor
                                                ? desactiveItemTextColor
                                                : 'text-success'
                                            } text-break`}
                                          >
                                            <strong
                                              className={`${
                                                desactiveItemTextColor
                                                  ? desactiveItemTextColor
                                                  : 'text-success'
                                              }`}
                                            >
                                              Commentaire interne :
                                            </strong>{' '}
                                            {
                                              orderLine.prescription
                                                .internalComment
                                            }
                                          </div>
                                        )}
                                      </>
                                    )}
                                </td>

                                <td
                                  className={`${desactiveItemTextColor}`}
                                  style={{width: '16%'}}
                                >
                                  <div 
                                    className="input-group" 
                                    ref={(el) => divInputRefs.current[item.id] = el}
                                  >
                                    {updateLoading ? // || closeOrderEventsLoading || accountingLoading || loadingOrderEvent
                                      <Spinner
                                        as="span"
                                        variant="secondary"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      /> :
                                      <>
                                        <input
                                          className="form-control py-0 px-1 orderline-amount-input"
                                          style={{maxWidth: '70px', minWidth:"50px"}}
                                          type="number"
                                          min="0"
                                          step="0.01"
                                          placeholder={(
                                            order.orderType === 'essdom' && item.hometrialProductAmount
                                              ? item.hometrialProductAmount.value
                                              : item.unitAmount.value
                                          )?.toFixed(2)}
                                          onFocus={() => {
                                            if (editingOrderLineId !== item.id) {
                                              setEditingOrderLineId(item.id);
                                            }
                                          }}
                                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                            if (e.key === 'Enter' && editingOrderLineId !== null) {
                                              handlePreUpdate(item)
                                              if (e.target instanceof HTMLInputElement) {
                                                e.target.blur();
                                              }
                                            }
                                          }}
                                          value={editingOrderLineId === item.id && orderLineValue !== undefined ? orderLineValue : ''}
                                          onChange={(e) => {
                                            let newValue = e.target.value;

                                            if (newValue.includes(',')) {
                                              newValue = newValue.replace(',', '.');
                                            }

                                            let parsedValue = parseFloat(newValue);

                                            let [integerPart, decimalPart] = newValue.split('.');

                                            if (integerPart && decimalPart && decimalPart.length > 2) {
                                              e.target.value = parsedValue.toFixed(2)
                                            }

                                            if (isNaN(parsedValue) || newValue === '') {
                                              setOrderLineValue(undefined);
                                            } else {
                                              parsedValue = Math.round(parsedValue * 100) / 100;
                                              setOrderLineValue(parsedValue);
                                            }
                                          }}
                                          disabled={/(FAC|AVR)/.test(orderLine.state?.toUpperCase() ?? '')}
                                        />
                                        <span className='d-flex align-items-center p-0 ms-1'>
                                          €
                                        </span>
                                        {editingOrderLineId === item.id && (
                                          <div className="divButtonOrderLineAmount">
                                            <button
                                              className="buttonValidateOrderLineAmount"
                                              onClick={() => {
                                                handlePreUpdate(item)
                                              }}
                                              disabled={updateLoading}
                                            >
                                              ✓
                                            </button>
                                            <button
                                              className="buttonCancelOrderLineAmount"
                                              onClick={() => {
                                                setOrderLineValue(undefined);
                                                setEditingOrderLineId(null);
                                              }}
                                            >
                                              ✗
                                            </button>
                                        </div>
                                      )}</>
                                    }
                                    
                                  </div>

                                </td>
                                <td
                                  className={`${desactiveItemTextColor}`}
                                  style={{width: '6%'}}
                                >
                                  {item.quantity}
                                </td>
                                <td
                                  className={`${desactiveItemTextColor}`}
                                  style={{width: '7%'}}
                                >
                                  {(
                                    item.unitAmount.value * item.quantity
                                  )?.toFixed(2)}
                                </td>
                                <td
                                  className={`${desactiveItemTextColor}`}
                                  style={{width: '7%'}}
                                >
                                  {[orderLine.aftersales ? 'SAV ' : '', item.aftersalesExchange ? 'ECH ' : '', orderLine.state?.toUpperCase()].join('')}{' '}
                                  {/* display stockState if item is a frame or accessory */}
                                  {item.item && (item.item[0].__typename === "fieldcollection_DOOLItemFrameFC" ||
                                    item.item[0].__typename === "fieldcollection_DOOLItemAccessoryFC")
                                    && orderLine.stockState?.toUpperCase()}
                                </td>
                                <td className={`${desactiveItemTextColor}`}
                                  style={{width: '6%'}}>
                                {/* if canBeDuplicated show the duplicate icon only once per orderline FAC */}
                                {canBeDuplicated && /FAC/.test(orderLine.state?.toUpperCase() ?? '') && j ===0 &&<>
                                  {
                                    loading ? 
                                    <Spinner
                                      as="span"
                                      variant="secondary"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    /> :                       
                                    <DuplicateIcon
                                      style={{ cursor: 'pointer' }}
                                      className={`header-title-icon ${
                                        desactiveItemTextColor
                                          ? desactiveItemTextColor
                                          : 'custom-blue-link'
                                      }`}
                                      //dupliquer une ligne
                                      onClick={() => {
                                        if(!loading)
                                        if (orderLine.state === 'fac') {
                                          if (!orderLine.prescription && 
                                            item.item[0].__typename !== "fieldcollection_DOOLItemFrameFC")
                                            handleDuplicate(orderLine)
                                          else
                                          {
                                            handleShowDupModal()
                                            setDuplicatedLine(orderLine)
                                          }
                                        }
                                      }}
                                    />
                                  }
                                </>}
                                </td>
                                <td style={{width: '7%'}}
                                  className={`${desactiveItemTextColor} col-30px px-2`}
                                >
                                  {item.item &&
                                  !['fieldcollection_DOOLItemLensFC'].includes(
                                    item.item[0].__typename
                                  ) ? (
                                    orderLine.state !== 'fac' && (
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={orderLine.id.toString()}
                                        checked={
                                          checkedIdList &&
                                          checkedIdList.includes(
                                            orderLine.id.toString()
                                          )
                                        }
                                        onChange={handleClick}
                                      />
                                    )
                                  ) : (
                                    <div style={{ width: '16px' }}></div>
                                  )}
                                </td>
                              </tr>
                              {orderLine.prescription &&
                                openPrescription ===
                                  +orderLine.prescription.id &&
                                // display prescription after last item
                                (j + 1) % orderLine.items.length === 0 &&
                                // display prescription after last orderline
                                getNumberOfEquipement() === k + 1 && (
                                  <tr className="position-relative bg-white">
                                    <td colSpan={7}>
                                      <Prescription
                                        isAlertOpen={false}
                                        beneficiary={
                                          orderLine.prescription.beneficiary[0]
                                        }
                                        prescription={orderLine.prescription}
                                        numberOfPrescription={i + 1}
                                        orderLines={groupOfOrderLines}
                                        order={order}
                                        isGroupOff={isGroupOff}
                                      />
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        )
                      })}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </td>
      </tr>
    )
  })

  return (
    <div className="border border-0 border-top border-bottom pb-2 mb-2 ">
      <table className="sticky-header w-100">
        <thead>
          <tr>
            {/* empty th for prescription col */}
            <th> </th>
            <th
              style={{
                position: 'sticky',
                top: '80px',
              }}
            >
              <table className="w-100">
                <thead>
                  <tr>
                    {order.orderType !== 'essdom' && (
                      <th
                        className="bg-transparent"
                        style={{ width: '290px' }}
                      ></th>
                    )}
                    <th>
                      {' '}
                      <table className="w-100">
                        <thead className="bg-white">
                          <tr>
                            <th className="w-50 px-2">Descriptif</th>
                            <th style={{width: '16%'}}>P.U.</th>
                            <th style={{width: '6%'}}>Qté.</th>
                            <th style={{width: '7%'}}>Total</th>
                            <th style={{width: '7%'}}>Statut</th>
                            <th style={{width: '6%'}}>{ canBeDuplicated ? 'x2' : ''}</th>
                            <th style={{width: '7%'}}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isCheckAll}
                                onChange={handleSelectAll}
                              />
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </th>
                  </tr>
                </thead>
              </table>
            </th>
          </tr>
        </thead>
        <tbody>
          {table}
          <tr>
            <td></td>
            <td>
              <table className="w-100 p-2">
                <tbody>
                  <tr className="hover-green">
                    <td style={{ width: '290px' }}></td>
                    <td>
                      <table className="w-100">
                        <tbody>
                          <tr className="mt-2">
                            <td className="w-70 px-2"></td>
                            <td className="col-115px d-flex justify-content-end pe-2"><span className={!calculators.isPaid ? "text-lightgray" : ""}><b>Total lignes : </b></span></td>
                            <td className="col-80px"><span className={!calculators.isPaid ? "text-lightgray" : ""}><b>{getOrderLineTotalAmount()?.toFixed(2)}</b></span></td>
                            <td className="col-50px pt-1 d-flex justify-content-end pe-2" style={{ fontSize: '14px' }}>
                              <span className={getOrderLineTotalAmount() - getCosiumAmount() ? "text-warning" : "text-success"}><b>Diff : </b></span>
                            </td>
                            <td className="col-62px pt-1" style={{ fontSize: '14px' }}>
                              <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                      <Tooltip id="tooltip-disabled">
                                        Différence du "Total lignes" avec le montant Cosium (= {getCosiumAmount().toFixed(2)})
                                      </Tooltip>
                                  }
                              >
                                <span className={getOrderLineTotalAmount() - getCosiumAmount() ? "text-warning" : "text-success"}>
                                  <b>{(getOrderLineTotalAmount() - getCosiumAmount()).toFixed(2)}</b>
                                </span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      {editionModal && (
        <DOModal
          show={!!editionModal}
          closeModal={() => setEditionModal('')}
          size="lg"
        >
          {editionModal === 'frame' ? (
            <EditFrameModal
              item={itemAndOrderLine.item}
              orderLine={itemAndOrderLine.orderLine}
              calculators={calculators}
              isHometrial={order.orderType === 'essdom'}
            />
          ) : editionModal === 'accessory'?
            <EditAccessoryModal
              item={itemAndOrderLine.item}
              orderLine={itemAndOrderLine.orderLine}
            />
          :(
            <EditOrderLineItemModal
              orderLineId={+itemAndOrderLine.orderLine?.id}
              item={itemAndOrderLine.item}
            />
          )}
        </DOModal>
      )}

      <Modal show={showDupModal} onHide={handleCloseDupModal}>
        <Modal.Header closeButton>
          <Modal.Title>Duplication de ligne {duplicatedLine?.id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {duplicatedLineHasLens() && <Form.Check
            type="checkbox"
            label="Conserver les verres (pas de commande de nouveaux verres)"
            className="float-start mt-2"
            defaultChecked={aftersalesKeepLenses}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAftersalesKeepLenses(e.target.checked)
            }
          />}
          {!duplicatedLineIsLensReplacement() && <Form.Check
            type="checkbox"
            label="Conserver la monture (pas déstockage de nouvelle monture)"
            className="float-start mt-2"
            defaultChecked={aftersalesKeepFrame}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setAftersalesKeepFrame(e.target.checked)
            }
          />}
        </Modal.Body>
        <Modal.Footer
          className="justify-content-between">
          {loading && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}

          {!loading && (
            <>
              <Button
                variant="secondary"
                onClick={handleCloseDupModal}
              >
                Annuler
              </Button>
              <Button
                variant="primary"
                onClick={() => handleDuplicate(duplicatedLine)}
              >
                Enregistrer
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default OrderDetailTable
