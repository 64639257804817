import HeaderTitle from './HeaderTitle'
import {
  Button,
  Container,
  // Row,
  // Col,
  // Form,
  // OverlayTrigger,
  // Tooltip,
  // Spinner,
} from 'react-bootstrap'
import {
  PrinterIcon,
  InboxInIcon,
  // EyeIcon
} from '@heroicons/react/outline'
import classes from './PrintCard.module.css'
import { useParams } from 'react-router-dom'
import { printOrderIdsPdf } from 'utils/functions'
import { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import DOModal from 'components/Modal/DOModal'
import OrderChangeTrayLocation from 'components/Modal/OrderChangeTrayLocation'
import OrderPayments from './OrderCard/OrderPayments'
// import OrderChangeLensesToAssemble from './Modal/OrderChangeLensesToAssemble'

const PrintCard: React.FC = () => {
  const { id } = useParams<ParamType>()
  const [isPrintBtnDisabled, setIsPrintBtnDisabled] = useState<boolean>(false)

  const GET_ORDER_TRAY_LOCATION = gql`
    query GET_ORDER_TRAY_LOCATION($id: Int!) {
      getDOOrder(id: $id) {
        id
        lensesToAssemble
        trayLocation
        workflowState
        orderType
      }
    }
  `
  const { loading, data } = useQuery(GET_ORDER_TRAY_LOCATION, {
    variables: {
      id: +id,
    },
    onCompleted: (data) => {
      // setDisabledToggle(
      //   !['in_verification', 'awaiting_preparation', 'in_preparation'].includes(
      //     data.getDOOrder.workflowState
      //   )
      // )
    },
  })

  const [showChangeLocationTrayModal, setShowChangeLocationTrayModal] =
    useState(false)
  // const [showLensesToAssembleModal, setShowLensesToAssembleModal] =
  //   useState(false)
  // const [disabledToggle, setDisabledToggle] = useState(false)

  return (
    <div className={`${classes.printCard} custom-card h-100`}>
      <HeaderTitle
        children={<PrinterIcon className="header-title-icon" />}
        title="Impression document"
        margin="mt-3"
      />
      <Button
        disabled={isPrintBtnDisabled}
        onClick={() => {
          setIsPrintBtnDisabled(true)
          printOrderIdsPdf(id, () => {
            setIsPrintBtnDisabled(false)
          })
        }}
        variant={`outline-secondary text-center m-1 w-100  btn-sm ${classes.printCardBtn}`}
      >
        Étiquette péniche
      </Button>

      <HeaderTitle
        children={<InboxInIcon className="header-title-icon" />}
        title="Emplacement pénichier"
        margin="mt-3"
      />
      <Container fluid className="mt-3 ps-1 p-0">
        <div>
          <p
            className={`m-0 p-0 text-break fw-bold ${
              data && !data.getDOOrder.trayLocation?.trim() && 'fst-italic'
            }`}
          >
            {data && data.getDOOrder.trayLocation?.trim()
              ? data.getDOOrder.trayLocation
              : 'Aucun emplacement'}
          </p>
        </div>

        <div className="mt-2">
          <Button
            variant={`outline-secondary text-center btn-sm w-100 ${classes.printCardBtn}`}
            disabled={loading}
            onClick={(e: any) => {
              setShowChangeLocationTrayModal(true)
              e.target.blur()
            }}
          >
            Ajouter/Modifier
          </Button>
          {data && (
            <DOModal
              show={showChangeLocationTrayModal}
              customSize="modal-647"
              closeModal={() => setShowChangeLocationTrayModal(false)}
            >
              <OrderChangeTrayLocation
                orderId={data.getDOOrder.id}
                trayLocation={data.getDOOrder.trayLocation}
              />
            </DOModal>
          )}
        </div>
      </Container>
      <OrderPayments />

      {/* {data && data.getDOOrder.orderType === 'panier' && (
        <>
          <HeaderTitle
            children={<EyeIcon className="header-title-icon" />}
            title="Commande verres"
            margin="mt-3"
          />

          {showLensesToAssembleModal ? (
            <div>
              <Spinner
                variant="primary ms-2"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </div>
          ) : (
            <div className="d-flex">
              <OverlayTrigger
                placement="left"
                key="tooltipLensesToAssemble"
                overlay={
                  <Tooltip id="tooltip">
                    Cette information indique la présence ou non de verres à
                    monter dans la commande (au moins 1 montage = VERRES A
                    MONTER)
                  </Tooltip>
                }
              >
                <Form.Group controlId="switchLenses" className="d-flex">
                  <Form.Check
                    type="switch"
                    defaultChecked={data.getDOOrder.lensesToAssemble}
                    onClick={() => setShowLensesToAssembleModal(true)}
                    disabled={disabledToggle}
                  />
                  <Form.Label className="m-0">
                    {data.getDOOrder.lensesToAssemble ? 'Oui' : 'Non'}{' '}
                    {disabledToggle
                      ? data.getDOOrder.workflowState === 'created' ||
                        data.getDOOrder.workflowState === 'awaiting_payment'
                        ? "(La commande n'est pas payée)"
                        : '(La commande est déjà expédiée)'
                      : ''}
                  </Form.Label>
                </Form.Group>
              </OverlayTrigger>
            </div>
          )}
          {data.getDOOrder.lensesToAssemble ? (
            <div className="d-inline-block p-2 bg-info fw-bold mt-2">
              PRESENCE de VERRES A MONTER
            </div>
          ) : (
            <div className="mt-2 p-2"> PAS de verres à monter</div>
          )}

          <DOModal
            show={showLensesToAssembleModal}
            customSize="modal-647"
            closeModal={() => setShowLensesToAssembleModal(false)}
          >
            <OrderChangeLensesToAssemble
              id={+id}
              lensesToAssemble={data.getDOOrder.lensesToAssemble}
            />
          </DOModal>
        </>
      )} */}
    </div>
  )
}

export default PrintCard
