import gql from 'graphql-tag'

const GET_ORDER_PRODUCTS = gql`
  query GET_ORDER_PRODUCTS($id: Int!) {
    getDOOrder(id: $id) {
      id
      orderLines {
        ... on object_DOOrderLine {
          id
          label
          status
          items {
            ... on object_DOOrderLineItem {
              id
              label
              quantity
              item {
                ... on fieldcollection_DOOLItemFrameFC {
                  frame {
                    ... on object_DOFrame {
                      id
                      name
                      imageUrls
                      images {
                        image {
                          fullpath
                        }
                      }
                      color{
                        ... on object_DOColor {
                          label
                        }
                      }
                      brand {
                        ... on object_DOBrand {
                          id
                          name
                        }
                      }
                    }
                  }
                }
                ... on fieldcollection_DOOLItemLensReplacementFC {
                  lensReplacement {
                    ... on object_DOLensReplacement {
                      id
                      imageUrls
                      images {
                        image {
                          fullpath
                        }
                      }
                    }
                  }
                }
                ... on fieldcollection_DOOLItemLensFC {
                  eyeSide
                  lens {
                    ... on object_DOLens {
                      id
                      lensDetails
                    }
                  }
                }
                ... on fieldcollection_DOOLItemAccessoryFC {
                  accessory {
                    ... on object_DOAccessory {
                      id
                      imageUrls
                      images {
                        image {
                          fullpath
                        }
                      }
                      brand {
                        ... on object_DOBrand {
                          id
                          name
                        }
                      }
                    }
                  }
                }
                ... on fieldcollection_DOOLItemGenericFC {
                  genericItem {
                    ... on object_DOGenericItem {
                      id
                      label
                      itemType
                      amount {
                        value
                      }
                      isNegative
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default GET_ORDER_PRODUCTS
