import React from 'react'
import { Toast } from 'react-bootstrap'
import { timestampToFrenchTime } from '../../utils/functions'

interface Props {
  title: string
  id?: number
  toastMessage: string
  onClose: () => void
  variant?: 'success' | 'danger'
}
const ToastMessage: React.FC<Props> = ({
  title,
  toastMessage,
  onClose,
  id,
  variant,
}) => {
  return (
    <Toast
      //if need autoclose : in useToasts.tsx add a setTimeout
      onClick={() => {
        onClose()
      }}
      bg={variant ?? ''}
    >
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
        <small>
          {id !== undefined ? timestampToFrenchTime(id) : ''}
        </small>
      </Toast.Header>
      <Toast.Body className={variant ? 'text-white' : 'text-muted'}>{toastMessage}</Toast.Body>
    </Toast>
  )
}

export default ToastMessage
