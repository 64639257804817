import React from 'react'
import { ToastContainer } from 'react-bootstrap'
import ToastMessage from '../components/Toast/ToastMessage'
import { useToasts } from '../components/Toast/useToasts'

// code inspiré de https://codesandbox.io/s/context-types-fix-forked-sn06u
interface IToastMessage {
  id: number
  title: string
  message: string
  onClose: () => void
  variant?: 'success' | 'danger'
}
interface ContextType {
  toasts: [] | IToastMessage[]
  toast: (payload: any) => void
}

export const ToastContext = React.createContext<ContextType>({
  toast: () => {},
  toasts: [],
})

export const ToastContextProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const { toast, toasts } = useToasts()

  return (
    <>
      <ToastContext.Provider value={{ toast, toasts }}>
        {children}
      </ToastContext.Provider>
      <ToastContainer
        position={'top-center'}
        className={`p-3 z-index-toast cursor position-fixed`}
      >
        {(toasts as Array<IToastMessage>).map((t, i) => {
          return (
            <ToastMessage
              key={i}
              title={t.title}
              toastMessage={t.message}
              onClose={t.onClose}
              id={t.id}
              variant={t.variant}
            />
          )
        })}
      </ToastContainer>
    </>
  )
}

export default ToastContextProvider
