import { useMutation } from '@apollo/client'
import BULK_UPDATE_DO_ORDERLINES from 'graphql/mutations/bulkUpdateDOOrderLines'
import { ChangeEvent, useContext } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'

interface Props {
  stateType: string
  checkedIdList: string[]
  resetStateType: (newStateType: string) => void
  comment: string
  setComment: (value: React.SetStateAction<string>) => void
  resetCheckedIdList: (orderLinesIds: string[]) => void
}
const ActivateOrDeactivateLinesForm: React.FC<Props> = ({
  stateType,
  checkedIdList,
  resetStateType,
  comment,
  setComment,
  resetCheckedIdList,
}) => {
  const { toast } = useContext(ToastContext)

  const [bulkUpdateDOOrderLines, { loading: bulkUpdateLoading }] = useMutation(
    BULK_UPDATE_DO_ORDERLINES,
    {
      variables: {
        orderLineIdList: JSON.stringify(checkedIdList),
        stateType,
        comment,
      },
      onCompleted: (data) => {
        if (
          data.bulkUpdateDOOrderLines.output.updateFailOrderLinesIds.length > 0
        ) {
          toast({
            title: 'Lignes non modifiées :',
            message: `${data.bulkUpdateDOOrderLines.output.updateFailOrderLinesIds?.join(
              ', '
            )} car facturée(s) (FAC), expédiée(s) (EXP), retournée(s) (RET) ou déjà ${
              stateType === 'active' ? 'activées' : 'désactivées'
            }`
          })
        }
        if (
          data.bulkUpdateDOOrderLines.output?.updateSuccessOrderLinesIds
            .length > 0
        ) {
          toast({
            title: 'Modifications enregistrées :',
            message:
              `Lignes ${stateType === 'desactive' ? 'dés' : ''}activées : ` +
              data.bulkUpdateDOOrderLines.output.updateSuccessOrderLinesIds?.join(
                ', '
              )
          })
        }
        resetStateType('')
        resetCheckedIdList([])
      },
    }
  )

  return (
    <Container className="mb-3">
      <Row>
        <div className="ms-4 w-75">
          <Form.Group controlId="activeTextArea">
            <Form.Control
              name="activeTextArea"
              placeholder={`Commentaire ${
                stateType === 'active' ? "d'" : 'de dés'
              }activation des lignes`}
              as="textarea"
              className="border border-light border-2 rounded"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setComment(e.target.value)
              }}
              value={comment}
            />
          </Form.Group>
        </div>
        <Col className="d-flex align-items-end">
          <Button className="me-2" size="sm" onClick={() => resetStateType('')}>
            Retour
          </Button>
          <Button
            size="sm"
            disabled={bulkUpdateLoading || comment === ''}
            onClick={() => {
              if (checkedIdList.length) bulkUpdateDOOrderLines()
              else {
                alert(
                  `Veuillez sélectionner au moins une ligne à ${
                    stateType === 'active' ? 'activer' : 'désactiver'
                  }`
                )
              }
            }}
          >
            {bulkUpdateLoading ? (
              <Spinner
                variant="light"
                className="mx-4"
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <>{stateType === 'active' ? 'Activer' : 'Désactiver'}</>
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ActivateOrDeactivateLinesForm
