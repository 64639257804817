import { Col, Container, Spinner, Row, Button } from 'react-bootstrap'
import { useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { ToastContext } from 'store/toast-context'
import { CM_DELETE_GENERIC_ITEM } from 'graphql/mutations/deleteGenericItem'
import { CQ_GET_ORDER } from 'graphql/queries/getOrder'

interface Props {
  orderLineId: Number
  item: any
  closeModal?: () => void
}

const EditOrderLineItemModal: React.FC<Props> = ({ orderLineId, item, closeModal }) => {

  const { toast } = useContext(ToastContext)
  const [awaitingDeleteConfirmation, setAwaitingDeleteConfirmation] = useState(false)
  const isGenericItem = /garantie/i.test(item.label) || item.item?.[0].hasOwnProperty('genericItem')

  const [deleteGenericItem, { loading: deleteLoading }] = useMutation(CM_DELETE_GENERIC_ITEM, {
    onCompleted: (data) => {
      if (closeModal) {
        closeModal()
      }
      if (!data?.deleteOrderLineItem?.output?.errorMessage) {
        toast({
          title: 'Succès :',
          message: `Suppression de la ${item.label}`,
        })
      } else if (data?.deleteOrderLineItem?.output?.errorMessage) {
        toast({
          title: 'Erreur :',
          message: data?.deleteOrderLineItem?.output?.errorMessage
        })
      }
    },
  })

  const clickCancelSaveHandler = () => {
    if (closeModal) closeModal()
  }

  const deleteGuaranteeHandler = async () =>{
    try{
      await deleteGenericItem({
        refetchQueries: [CQ_GET_ORDER],
        awaitRefetchQueries: true,
        variables: { orderLineItemId: +item.id },
      })
    }catch(ex){
      console.error(ex)
    }
  }

  return (
  !awaitingDeleteConfirmation ? <div>
    <Container>
      <Row>
        <Col xs={6}>
          <b>Produit</b>
        </Col>
        <Col>
          {item?.item?.[0].__typename==='fieldcollection_DOOLItemShippingFC'?'Frais de port'
          :item.label}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={6}>
          <b>Prix unitaire actuel (€)</b>
        </Col>
        <Col>
          {item.unitAmount.value.toFixed(2)}
        </Col>
      </Row>
    </Container>
    <div className={isGenericItem?'d-flex justify-content-between align-items-center':''}>
      <div className="mt-3 text-center">
        <Button
          variant="outline-primary bg-white text-primary"
          className="m-2"
          onClick={clickCancelSaveHandler}
        >
          Annuler
        </Button>
      </div>

      {isGenericItem && <Button
        variant="danger"
        className="m-2"
        onClick={()=>setAwaitingDeleteConfirmation(true)}
      >
        Supprimer
      </Button>}
    </div>
  </div> : 
  <div>
    <h4>Êtes-vous sûr(e) de vouloir supprimer la {item.label} de l'équipement ?</h4>
    <div className='mt-3 text-center'>
        <Button
          variant="success"
          className="m-2"
          disabled={deleteLoading}
          onClick={deleteGuaranteeHandler}
        >
          {deleteLoading? 
          <Spinner
              variant="light"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />:'Confirmer'}
        </Button>
        <Button
          variant="outline-primary"
          className="m-2"
          disabled={deleteLoading}
          onClick={()=>setAwaitingDeleteConfirmation(false)}
        >
          Annuler
        </Button>
    </div>
  </div>
  )
}

export default EditOrderLineItemModal