import { useMutation } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { ToastContext } from 'store/toast-context'
import ProductSearchForm from './UpdateOrderLines/ProductSearchForm'
import MenuContext from 'store/menu-context'
import AuthContext from 'store/auth-context'
import { REPLACE_PRODUCT } from 'graphql/mutations/replaceProduct'

interface Props {
  orderLineItem: any
  selectedRadio: string
  closeModal?: () => void
}

const EditProductSearchForm: React.FC<Props> = ({
  orderLineItem,
  selectedRadio,
  closeModal
}) => {
  const { toast } = useContext(ToastContext)
  const menuContext = useContext(MenuContext)
  const authCtx = useContext(AuthContext)

  // state for SearchInput to display or hide the result
  const [searchInputLabel, setSearchInputLabel] = useState('')
  const [salePrice, setSalePrice] = useState('')

  // selectedFrame id, frameName and oldFrameId
  const [selectedProduct, setSelectedProduct] = useState({} as any)

  //Mutation : CM update orderLineItem and create events
    // accessory or frame update
    const [replaceProduct, { loading: replaceLoading }] = useMutation(REPLACE_PRODUCT, {
      onCompleted: (data) => {
        if (data.replaceProduct.output.cmFailComment) {
          toast({
            title: 'Attention ! ',
            message: data.replaceProduct.output.cmFailComment
          })
        } else {
          toast({
            title: 'Modification enregistrée : ',
            message: `Équipement ${data.replaceProduct.output.orderLineItem.orderLine?.[0]?.id} : ${selectedProduct.productName}`,
          })
        }

        if(data.replaceProduct.output.toPutPhysicallyBackToStock && (authCtx.user.roles[0] === 'logistique_kehl' || authCtx.user.fullName === 'admin'))
          menuContext.setSideBarNewEventCount(++menuContext.sideBarNewEventCount)

        if (closeModal)
          // Si on connait la fonction qui permet de fermer la modale, on la ferme
          closeModal()
      }
    })

  //execute the mutation
  const saveHandler = async () => {
    try {
      //accessory  
      if(orderLineItem.item[0].__typename === 'fieldcollection_DOOLItemAccessoryFC'){
        await replaceProduct({
          variables: {
            orderLineItemId: +orderLineItem.id,
            newProductId: +selectedProduct.id,
            salePrice: parseFloat(salePrice),
          },
        })
      }
      // frame
      else {
        await replaceProduct({
          variables: {
            orderLineItemId: +orderLineItem.id,
            newProductId: +selectedProduct.id
          },
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    setSearchInputLabel(
      selectedRadio === 'frame' ? 'Nom Monture' : 'accessory'?'Nom Accessoire':'Nom Verres'
    )
  }, [selectedRadio])

  return (
    <div className="SearchInput">
      <ProductSearchForm
        isFrame={selectedRadio === 'frame'}
        placeholder={searchInputLabel}
        setSelectedProduct={setSelectedProduct}
        salePrice={salePrice}
        setSalePrice={setSalePrice}
      />

      {Object.keys(selectedProduct).length > 0 && (
        <Button
          onClick={saveHandler}
          disabled={replaceLoading}
          className="float-end"
        >
          {replaceLoading? (
            <Spinner
              variant="light"
              className="mx-4"
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <>Enregistrer</>
          )}
        </Button>
      )}
    </div>
  )
}

export default EditProductSearchForm
