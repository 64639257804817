import { gql } from '@apollo/client'
import { DO_ACCESSORY_FIELDS } from 'graphql/fragments/DOAccessoryFields'
import { DO_FRAME_FIELDS } from 'graphql/fragments/DOFrameFields'

const UPDATE_DO_PRODUCT_STOCK_LOCATION = gql`
  ${DO_FRAME_FIELDS}
  ${DO_ACCESSORY_FIELDS}
  mutation updateDOProductStockLocation(
    $id: Int!
    $stockLocation: String!
    $force: Boolean!
  ){
    updateDOProductStockLocation(
      id: $id
      stockLocation: $stockLocation
      force: $force
    ){
      success
      output {
        frame {
          ...DOFrameFields
        }
        accessory{
          ...DOAccessoryFields
        }
        exists
      }
    }
  }
`

export default UPDATE_DO_PRODUCT_STOCK_LOCATION