import './ModalAddress.css'
import React from 'react'
import { useQuery } from '@apollo/client'
import GET_ORDER_PRODUCTS from '../../graphql/queries/getOrderProducts'
import './ModalOrderProducts.css'
import { useTranslation } from 'react-i18next'
import { Container, Col, Row } from 'react-bootstrap'
import SkeletonBase from 'components/Skeleton/SkeletonBase'
import Skeleton from 'react-loading-skeleton'
import removeAccents from 'remove-accents'
interface Props {
  orderId: string,
  orderKey: string,
  fullName: string,
  phone: string
}

// function to remove accents and replace spaces and slashes by dashes
const urlize = (urlPart: string): string => {
    return removeAccents(urlPart).trim().toLowerCase().replace(/([ ]+|\/)/g, '-')
}
const getFrontLink = (brandName: string, productName: string, productColor: string | null, productId: number): string => {
    const domainUrl = process.env.REACT_APP_PIMCORE_URL_DOMAIN || ''
    const brand = urlize(brandName.replace(/[?|!]/g, '')) // Remove "?" or "!" but keep "."
    const name = urlize((productName + (productColor ? ' ' + productColor : '')).replace(/[.]/g, '')) // Remove "?", "!" and "."
    return `${domainUrl}/${brand}/${name}/lunettes,i${productId}`
}

const ModalOrderProducts: React.FC<Props> = ({ orderId, orderKey, fullName, phone }) => {
  const { t } = useTranslation()

  const { loading, data } = useQuery(GET_ORDER_PRODUCTS, {
    variables: {
      id: parseInt(orderId),
    },
  })

  if (loading)
    return (
      <div className="d-flex justify-content-center">
        <div className="px-5 w-50">
          <Skeleton height={70} />
          <SkeletonBase />
        </div>
      </div>
    )
  if (!data || !data.getDOOrder.orderLines) return <p>Aucun produit trouvé</p>

  const { REACT_APP_PIMCORE_URL_DOMAIN } =
    process.env

  // Tableau qui comporte les différents produits
  // [{ id, brandName, brandModelName, image, quantity, lenses }]
  let products: any[] = []
  let imagesUrlsT: any = ''
  for (let orderLine of data.getDOOrder.orderLines) {
    if (orderLine.status === 'off') continue
    let product: any = {}
    let lenses: any[] = []
    for (let orderLineItem of orderLine.items) {
      if (orderLineItem.item != null)
        for (let item of orderLineItem.item) {
          switch (item.__typename) {
            case 'fieldcollection_DOOLItemFrameFC':
              product['quantity'] = orderLineItem.quantity

              if (item.frame) {
                let frontLink = getFrontLink(item.frame[0]?.brand?.name,item.frame[0].name ,item.frame[0].color.label, +item.frame[0]?.id)         
                product['id'] = item.frame[0]?.id
                product['frontLink'] = frontLink
                product['brandName'] = item.frame[0]?.brand?.name
                imagesUrlsT = JSON.parse(item.frame[0]?.imageUrls)
                if (item.frame[0]?.images || imagesUrlsT?.length) {
                  product['image'] = item.frame[0].images
                    ? `${REACT_APP_PIMCORE_URL_DOMAIN}` +
                      item.frame[0].images[0]?.image?.fullpath
                    : imagesUrlsT.find((obj: any) => obj.order === 1).url
                }
              }
              product['brandModelName'] = orderLineItem.label
              break

            case 'fieldcollection_DOOLItemAccessoryFC':
              product['quantity'] = orderLineItem.quantity

              product['id'] = item.accessory[0]?.id
              product['brandName'] = item.accessory[0]?.brand?.name
              imagesUrlsT = JSON.parse(item.accessory[0]?.imageUrls)
              if (item.accessory[0]?.images || imagesUrlsT?.length) {
                product['image'] = item.accessory[0]?.images
                  ? `${REACT_APP_PIMCORE_URL_DOMAIN}` +
                    item.accessory[0].images[0]?.image?.fullpath
                  : imagesUrlsT.find((obj: any) => obj.order === 1).url
              }

              product['brandModelName'] = orderLineItem.label
              break

            case 'fieldcollection_DOOLItemLensReplacementFC':
              product['quantity'] = orderLineItem.quantity

              product['id'] = item.lensReplacement[0]?.id
              product['brandName'] = 'direct-optic' // FIXME à revoir pour la construction de l'URL
              imagesUrlsT = JSON.parse(item.lensReplacement[0]?.imageUrls)
              if (item.lensReplacement[0]?.images || imagesUrlsT?.length) {
                product['image'] = item.lensReplacement[0]?.images
                  ? `${REACT_APP_PIMCORE_URL_DOMAIN}` +
                    item.lensReplacement[0].images[0]?.image?.fullpath
                  : imagesUrlsT.find((obj: any) => obj.order === 1).url
              }

              product['brandModelName'] = orderLineItem.label

              break

            case 'fieldcollection_DOOLItemLensFC':
              const lens: any = {}

              lens.eyeSide = item.eyeSide
              lens.details = item.lens ? item.lens[0]?.lensDetails : '-'

              lenses.push(lens)
              break
            case 'fieldcollection_DOOLItemGenericFC':
              if(!item.genericItem[0].isNegative && item.genericItem[0].itemType === 'generic') {
                product['quantity'] = orderLineItem.quantity
                product['id'] = item.genericItem[0]?.id
                product['brandModelName'] = orderLineItem.label
              }
            break
          }
        }

      if (lenses.length > 0) {
        // tri pour que l'oeil droit soit avant l'oeil gauche (au cas où)
        lenses.sort((a, b) => {
          return a.eyeSide - b.eyeSide
        })

        product['lenses'] = lenses
      }
    }
    if (Object.keys(product).length > 0) {
      // check if obj product is not empty to ignore shipping fee, vouchers ...
      products.push(product)
    }
  }

  // Sort by with lenses to no lens
  products?.sort((productA: any, productB: any) => productA?.lenses?.length > productB?.lenses?.length ? 1 : -1)

  return (
    <Container className="d-flex flex-column"> 
      <div className="d-flex mb-3 equipment-order-details">
        <span>{orderKey}</span>
        <span>{fullName}</span>
        <span>{phone}</span>
      </div>
      {products.map((product, productIndex) => {
        return (
          <Row className="d-flex mt-2 mb-3 equipment" key={'product-' + product.id + '-' + productIndex}>
            <Col xs={3} className="equipment-image">
            {product.image && (
              <a
                href={product.frontLink}
                target="_blank"
                rel="noreferrer"
              >
                {/* FIXME gérer construction URL dans une méthode commune FO + BO */}
                <img
                  src={product.image}
                  alt={product.brand}
                  className="brand-model-image"
                />
              </a>
            )}
            </Col>
            <Col>
                <div className="fw-bold">{product.brandModelName} 
                {!product?.lenses && <>{' '}({product.quantity})</>}</div>
                {product?.lenses?.map((lens: any, lensIndex: any) => {
                  return (
                    <div className="small" key={lensIndex.toString()}>
                      <span className="fw-bold">
                        {t('eyeSide.' + lens.eyeSide)} :
                      </span>{' '}
                      {lens.details}
                    </div>
                  )
                })}
            </Col>
          </Row>
        )}
      )}
      <div className="text-center">Nombre de produits : {products.length}</div>
    </Container>
  )
}

export default ModalOrderProducts
